import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
// import './WalletFormItems/scss/walletModel.scss';
import Transitions from 'ui-component/extended/Transitions';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center',alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '474px',
    border: '1px #37AE02 solid',
    // borderRadius: '34px',
    backgroundColor: '#000'
  },
  '& .MuiDialogContent-root': {
    padding: '16px 26px',
    paddingTop: '60px',
    background: 'linear-gradient(180deg, rgba(0, 255, 209, 0.12) 41.15%, rgba(44, 87, 81, 0.00) 100%);'
  },
  // '& .MuiTabs-flexContainer': {
  //   // width: '100%',
  //   border: '1px #2E800F solid',
  //   borderRadius: '35px',
  //   background: '#060708',
  //   height: '50px',
  //   boxSizing: 'border-box',
  //   padding: '4px',
  //   alginItem: 'center'
  // },
  // '& .MuiTabs-indicator ': {
  //   display: 'none'
  // },
  '& .Mui-selected': {
    color: '#013C00 !important',
    backgroundColor: '#4FB02B',
    fontSize: '20px',
    borderRadius: '46px 46px 46px 46px',
    height: '40px'
  }
}));

export default function NoticeModel(props) {
  const { open, onClose, content } = props;
  return (
    <div>
      <BootstrapDialog
        open={open}
        // keepMounted={false}
        // disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-wallet"
        TransitionComponent={Transition}
      >
        <Box>
          <IconButton
            aria-label="close"
            onClick={() => onClose && onClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#576788'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent>
          <div style={{ textAlign: 'center', fontSize: '22px' }}>Notice</div>

          <div style={{ marginTop: '20px', paddingBottom: '20px' }}>{content}</div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
