const routerObj = {
    og_roultte: '/game_roulette',
    og_polerstar: '/game_hustler',
    og_crash: '/game_crash',
    og_plinko: '/game_plinko'
    // og_prediction: '/game_prediction'
  };
 function goGame(item,navigate) {
    if (process.env.REACT_APP_NODE_ENV === 'test') return;
    if (item.brand === 1) {
      // if (matchUpMd && routerObj[item.bet.game_id] === '/game_plinko') return;
      navigate(routerObj[item.bet.game_id]);
      return;
    }
    navigate('/third-game', { state: { gameID: item.bet.game_id, gameName: item.game_name, brand: item.brand } });
  }
export {
    goGame,
};
