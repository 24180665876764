import React, { useEffect, useState, useRef, memo, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toThousands, simplifyNum } from 'utils/commonUtils';
import { useSnackbar } from 'notistack';

import { left_arrow, left_arrow_blue, right_arrow, right_arrow_blue } from 'static/images';

const TradeList = memo(
  (props) => {
    const { type } = props;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const tradeList = useSelector((state) => state.customization.tradeList);
    const isLogin = useSelector((state) => state.customization.isLogin);
    const listRef = useRef([]);
    const [list, setList] = useState([]);
    const [elist, seteList] = useState([]);
    useEffect(() => {
      if (listRef.current.length > 0) return;
      listRef.current = tradeList;
      setList(listRef.current);
    }, [tradeList]);
    useEffect(() => {
      console.log('isLogin', isLogin);
      if (isLogin === 0) return;
      starx.on('cmd_trademsg', (res) => {
        dealList(res.trades);
      });
    }, [isLogin]);
    const goTrade = (item) => {
      // enqueueSnackbar('COMING SOON', { variant: 'warning' });
      // return
      navigate(`/game_trade?coin=${item.Symbol}`);
    };
    function dealList(data) {
      // console.log(data,'data')
      // return
      const obj = data[0];
      const { BaseVolume, HighPrice, LastPrice, LowPrice, Percent, QuoteVolume } = obj;
      const saveList = [...listRef.current];
      const newList = saveList.map((item, index) => {
        if (item.Symbol === obj.Symbol) {
          item.isDis = LastPrice * 1 > item.LastPrice * 1;
          item.BaseVolume = BaseVolume;
          item.HighPrice = HighPrice;
          item.LastPrice = LastPrice;
          item.LowPrice = LowPrice;
          item.Percent = Percent;
          item.QuoteVolume = QuoteVolume;
        }
        return item;
      });
      listRef.current = newList;
      setList(newList);
      seteList(newList);
    }
    const handleMouseEnter = (e, type) => {
      const img = e.target.querySelector('img');
      if (!img) return;

      if (type === 'left') {
        img.src = left_arrow_blue;
      } else {
        img.src = right_arrow_blue;
      }
    };
    const handleMouseLeave = (e, type) => {
      const img = e.target.querySelector('img');

      if (!img) return;
      if (type === 'left') {
        img.src = left_arrow;
      } else {
        img.src = right_arrow;
      }
    };

    const renderTradeCard = useCallback(() => {
      return (
        <div className="trade" style={{ display: type === 'list' ? 'none' : 'block' }}>
          <Swiper
            modules={[Navigation, Autoplay]}
            allowTouchMove={false}
            spaceBetween={10}
            navigation={{
              prevEl: `.swiper-trade-prev`,
              nextEl: `.swiper-trade-next`,
              disabledClass: 'disable'
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                slidesPerGroup: 2
              },
              450: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              600: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              900: {
                slidesPerView: 5,
                spaceBetween: 20,
                slidesPerGroup: 5
              },
              1100: {
                slidesPerView: 7,
                spaceBetween: 10
              }
            }}
          >
            {list.map((item, index) => (
              <SwiperSlide className="trade-item" key={item.Symbol}>
                <div className="three-item">
                  <div className="item-img">
                    <img
                      style={{ borderRadius: '16px' }}
                      src={`https://21bits.s3.ap-southeast-1.amazonaws.com/coinlist/${item.Symbol}.png`}
                      alt=""
                      width={32}
                    />
                    <div style={{ marginLeft: '8px' }}>
                      <div style={{ color: '#FFFFFF', fontSize: '14px' }}>{item.Symbol}</div>
                      <div style={{ color: item.Percent * 1 < 0 ? '#ff4949' : '#72f238' }}>
                        {(item.Percent * 1 > 0 ? '+' : '') + (item.Percent * 1).toFixed(2)}%
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingBottom: '16px', fontSize: '16px' }}>{toThousands(item.LastPrice * 1)}</div>
                  <div className="item-lv">
                    <div style={{ color: '#b1b6c6', marginRight: '5px', fontSize: '14px' }}>24h Vol:</div>
                    <div style={{ color: '#ffb018', marginRight: '5px', fontSize: '14px' }}>${simplifyNum(item.QuoteVolume, 0)}</div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="swiper-nav">
            <div
              className={`swiper-trade-prev  swiper-popular-games-prev`}
              onMouseEnter={(e) => {
                handleMouseEnter(e, 'left');
              }}
              onMouseLeave={(e) => {
                handleMouseLeave(e, 'left');
              }}
            >
              <img src={left_arrow} alt="arrow" />
            </div>
            <div
              className={`swiper-trade-next swiper-popular-games-next`}
              onMouseEnter={(e) => {
                handleMouseEnter(e, 'right');
              }}
              onMouseLeave={(e) => {
                handleMouseLeave(e, 'right');
              }}
            >
              <img src={right_arrow} alt="arrow" />
            </div>
          </div>
        </div>
      );
    }, [list]);
    return (
      <>
        <div className="tradeList" style={{ display: type === 'list' ? 'block' : 'none' }}>
          {list.map((item) => (
            <div key={item.Symbol} className="listItem" onClick={() => goTrade(item)}>
              <img
                style={{ borderRadius: '10px' }}
                src={`https://21bits.s3.ap-southeast-1.amazonaws.com/coinlist/${item.Symbol}.png`}
                alt=""
                width={20}
              />
              <div style={{ margin: '0 12px', fontSize: '13px', color: '#b1b6c6', fontFamily: `'Geogrotesque Bl', sans-serif` }}>
                {item.Symbol}
              </div>
              <div style={{ marginRight: '12px', marginLeft: 'auto' }}>{toThousands(item.LastPrice * 1)}</div>
              <div>
                {!item.isDis ? (
                  <svg width="8" height="10" viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg" color="#FF4949" size="8">
                    <path
                      d="M8 4.449L4 7.357 0 4.449v2.643L4 10l4-2.908V4.45zM8 0L4 2.908 0 0v2.643l4 2.908 4-2.908V0z"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                ) : (
                  <svg width="8" height="10" viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg" color="#72f238" size="8">
                    <path
                      d="M8 5.551L4 2.643 0 5.551V2.908L4 0l4 2.908V5.55zM8 10L4 7.092 0 10V7.357l4-2.908 4 2.908V10z"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
        {renderTradeCard()}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.type === nextProps.type;
  }
);

export default TradeList;
