import {useState, useEffect, useCallback} from 'react';
import {axios} from './axios';
import {awaitWrap, stringify} from 'utils/utilTools';
import qs from 'qs'
export function fetchPost(url, data = {}, config = {}){
    if (data instanceof FormData) {
        return axios.post(url, data, config);
    } else {
        if (!config.headers) {
            config.headers = {};
        }
        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            return axios.post(url, JSON.stringify(data), config);
        }
        return axios.post(url, qs.stringify(data), config);
    }
}

export function fetchGet(url, data, config) {
    let reqURL = data?`${url}?${stringify(data)}`:url;
    return axios.get(reqURL, config,);
}

export function useFetchPost(url, params = {}, require) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    const reload = useCallback(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    async function loadData() {
        if (require.every((item) => !!item)) {
            setLoading(true);
            const [res] = await awaitWrap(fetchPost(url, params));
            if (res) {
                setData(res.data);
            }
            setLoading(false);
        } else {
          setData({});
        }
    }

    return {data, loading, reload};
}

export function useFetchGet(url, params = {}, require) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    const reload = useCallback(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    async function loadData() {
        if (require.every((item) => !!item)) {
            setLoading(true);
            const [res] = await awaitWrap(fetchGet(url, params));
            if (res) {
                setData(res.data);
            }
            setLoading(false);
        } else {
          setData({});
        }
    }

    return {data, loading, reload};
}

export function useFetchPostPage(url, params = {}, require, customerParams = {}) {
    const [data, setData] = useState(undefined);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const customerLoad = customerParams && customerParams.customerLoad;
        if (!customerLoad) {
            loadData();
        }
    }, [...Object.values(params).concat(require)]);

    const reload = useCallback((reloadCallback) => {
        return loadData(reloadCallback);
    }, [...Object.values(params).concat(require)]);

    async function loadData(reloadCallback) {
        if (require.every((item) => !!item)) {
            setLoading(true);
            let res;
            [res, error] = await awaitWrap(fetchPost(url, params));
            if (res) {
                setData(res.data.records);
                setTotal(res.data.total);
                if (typeof reloadCallback === "function") {
                    reloadCallback(res.data.total);
                }
            }
            setLoading(false);
        } else {
            setData([]);
            setTotal(0);
        }
    }

    return {data, loading, total, reload};
}

export function useFetchPostArr(url, params = {}, require) {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    const reload = useCallback(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    async function loadData() {
        if (require.every((item) => !!item)) {
            setLoading(true);
            let res;
            [res, error] = await awaitWrap(fetchPost(url, params));
            if (res) {
                setData(res.data);
            }
            setLoading(false);
        } else {
          setData([]);
        }
    }

    return {data, loading, reload};
}


export function useFetchPostArrPage(url, params = {}, require) {
    const [data, setData] = useState(undefined);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    const reload = useCallback(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    async function loadData() {
        if (require.every((item) => !!item)) {
            setLoading(true);
            let res;
            [res, error] = await awaitWrap(fetchPost(url, params));
            if (res) {
                setData(res.data.records);
                setTotal(res.data.total);
            }
            setLoading(false);
        } else {
          setData([]);
          setTotal(0);
        }
    }

    return {data, loading,total, reload};
}

export function useFetchGetArr(url, params = {}, require) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    const reload = useCallback(() => {
        loadData();
    }, [...Object.values(params).concat(require)]);

    async function loadData() {
        if (require.every((item) => !!item)) {
            setLoading(true);
            const [res] = await awaitWrap(fetchGet(url, params));
            if (res) {
                setData(res.data);
            }
            setLoading(false);
        } else {
          setData([]);
        }
    }

    return {data, loading, reload};
}
