import { useEffect } from 'react';
const IMAGE_URL = {
  1: 'https://s3.21bits.io/21bitsGame/image/',
  2: 'https://s3.21bits.io/PPGames/image/',
  3: 'https://s3.21bits.io/BGaming/image/bg_',
  4: 'https://s3.21bits.io/popGame/image/pop_',
  5: 'https://s3.21bits.io/hacksaw/image/',
  6: 'https://s3.21bits.io/nux/image/',
  8: 'https://s3.21bits.io/21bitsGame/image/',
  9: 'https://s3.21bits.io/hacksaw/image/',
  10: 'https://s3.21bits.io/hacksaw/image/',
};
const VIP_COLOR = {
  Unranked:'#576788',
  Bronze: '#CE7D4B',
  Silver: '#B1BECC',
  Gold: '#E9DA48',
  Platinum: '#42A1BC',
  Diamond: '#DD8681',
  Orichalcum: '#57F2D8',
  Mithril: '#8DCCFF'
};
function getSplitString(str, pre, aff) {
  const strSplit = str ? str.slice(0, pre) + '....' + str.substr(aff) : '';
  return strSplit;
}

function getSplitDecimal(str, pre) {
  if (typeof str !== 'string') return '-';
  const arr = str.split('.');
  const strSplit = arr[0] + '.' + arr[1].slice(0, pre);
  return strSplit;
}

/**
 *
 * @param {React.EffectCallback} fn
 * @param {React.DependencyList} deps
 * @param {number} ms
 */
function useDebouncedEffect(fn, ms, deps) {
  useEffect(() => {
    let clean = null;
    const timer = setTimeout(() => {
      clean = fn();
    }, ms);
    return () => {
      clearTimeout(timer);
      if (clean) clean();
    };
  }, deps);
}

function toThousands(num = '0') {
  if (isNaN(num) || !num) return 0;
  return (num + '').replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  });
}

function simplifyNum(number, float) {
  if (!number && number != 0) return number;
  var str_num;
  if (number >= 1e3 && number < 1e6) {
    // @ts-ignore
    str_num = float ? (number / 1e3).toFixed(float) : parseInt(number / 1e3);
    return str_num + 'K';
  } else if (number >= 1e6 && number < 1e9) {
    // @ts-ignore
    str_num = float ? (number / 1e6).toFixed(float) : parseInt(number / 1e6);
    return str_num + 'M';
  } else if (number >= 1e9) {
    // @ts-ignore
    str_num = float ? (number / 1e9).toFixed(float) : parseInt(number / 1e9);
    return str_num + 'B';
  } else {
    return number;
  }
}
function random(min, max) {
  const random = Math.random();
  min = Math.round(min);
  max = Math.floor(max);

  return random * (max - min) + min;
}

function getImageUrl(type, games) {
  let imageId = type === 6 && games.suffix === 'png' ? games.img : games.game_id;
  let orginUrl = type === 6 && games.suffix === 'png' ? 'https://fungamess.games/images/games/' : IMAGE_URL[type];
  const url = orginUrl + imageId + '.' + games.suffix;
  return IMAGE_URL[type] ? url : '';
}

function debounce(fn, wait) {
  let timer = (...args) => {
    // @ts-ignore
    const context = thisif(timer);
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(context, args);
    }, wait);
  };
}
function timestampToTime(timestamp, flag) {
  var date = new Date(timestamp); // 将12位时间戳转换为13位时间戳（乘以1000）

  var year = date.getFullYear();
  var month = ('0' + (date.getMonth() + 1)).slice(-2);
  var day = ('0' + date.getDate()).slice(-2);
  var hour = ('0' + date.getHours()).slice(-2);
  var minute = ('0' + date.getMinutes()).slice(-2);
  var second = ('0' + date.getSeconds()).slice(-2);
  var milliseconds = ('00' + date.getMilliseconds()).slice(-3);

  var formattedTime = hour + ':' + minute + ':' + second + ':' + milliseconds;
  var formattedTimeAll = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  return flag ? formattedTimeAll : formattedTime;
}

function visibilitychangePage() {
  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden') {
    }
    if (document.visibilityState === 'visible') {
      location.reload();
    }
  });
}
function removeSocketOff(data) {
  data.map((I) => {
    starx.off(I);
  });
}

function getFps(dispatch) {
  const intervel = 1000;
  var rAF = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, intervel / 60);
      }
    );
  })();

  var frame = 0;
  var allFrameCount = 0;
  var lastTime = Date.now();
  var lastFameTime = Date.now();

  var loop = function () {
    var now = Date.now();
    var fs = now - lastFameTime;
    var fps = Math.round(intervel / fs);

    lastFameTime = now;
    allFrameCount++;
    frame++;

    if (now > intervel + lastTime) {
      var fps = Math.round((frame * intervel) / (now - lastTime));
      dispatch({ type: '@customization/SET_FPS', fps: fps });

      frame = 0;
      lastTime = now;
    }

    rAF(loop);
  };

  loop();
}

function getNetworkIp() {
  let ip = 'localhost';
  try {
    const network = Os.networkInterfaces(); //获取本机的网路
    for (const iter in network) {
      const faces = network[iter];
      for (const interfaceF of faces) {
        if (interfaceF.family === 'IPv4' && interfaceF.address !== '127.0.0.1' && !interfaceF.internal) {
          ip = interfaceF.address;
          return ip;
        }
      }
    }
  } catch (e) {}
  return ip;
}
function sortObjectArrayByPropertyFirstLetter(arr, propertyName) {
  function compareFunc(a, b) {
    if (a[propertyName][0].toLowerCase() < b[propertyName][0].toLowerCase()) return -1;
    if (a[propertyName][0].toLowerCase() > b[propertyName][0].toLowerCase()) return 1;
    if (a[propertyName].toLowerCase() < b[propertyName].toLowerCase()) return -1;
    if (a[propertyName].toLowerCase() > b[propertyName].toLowerCase()) return 1;
    return 0;
  }
  arr.sort(compareFunc);
  const numbers = [];
  const strings = [];
  for (let i = 0; i < arr.length; i++) {
    if (!isNaN(parseInt(arr[i][propertyName]))) {
      numbers.push(arr[i]);
    } else {
      strings.push(arr[i]);
    }
  }
  return [...strings, ...numbers];
}
function isObjectEmpty(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]' && Object.keys(obj).length === 0;
}

function generateUniqueID() {
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);
  const uniqueID = array.join('-');
  return uniqueID;
}
function getDeviceID() {
  const userAgent = navigator.userAgent;
  const uniqueID = generateUniqueID();
  return {
    userAgent,
    uniqueID
  };
}
function clearLocalInfor() {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('userInfo');
  window.localStorage.removeItem('levelInfo');
  window.localStorage.removeItem('totalBet');
  window.localStorage.removeItem('favorGame');
  window.localStorage.removeItem('usdtAccount');
  window.localStorage.removeItem('userAccount');
  window.localStorage.removeItem('address');
}
function getVipColor(str) {
  const strSplit = str.split('_')[0];
  return strSplit in VIP_COLOR? VIP_COLOR[strSplit] : VIP_COLOR.Unranked;

}
export {
  getSplitString,
  getSplitDecimal,
  useDebouncedEffect,
  toThousands,
  simplifyNum,
  random,
  getImageUrl,
  debounce,
  timestampToTime,
  visibilitychangePage,
  removeSocketOff,
  getFps,
  getNetworkIp,
  sortObjectArrayByPropertyFirstLetter,
  isObjectEmpty,
  getDeviceID,
  clearLocalInfor,
  getVipColor
};
