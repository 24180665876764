import React, { useState } from 'react';
import PendingRewards from 'views/claim/components/PendingRewards';
import { calendar } from 'static/images';
const CalendarSection = () => {
  const [isRewardsDialog, setIsRewardsDialog] = useState(false);
  return (
    <div>
      <div style={{background:'#0A0C13',padding:'5px',borderRadius:'5px',marginRight:'5px'}}>
        <img
          src={calendar}
          alt=""
          srcset=""
          width={20}
          style={{ cursor: 'pointer' }}
          onClick={() => setIsRewardsDialog(true)}
        />
      </div>
      <PendingRewards onClose={() => setIsRewardsDialog(false)} open={isRewardsDialog} />
    </div>
  );
};
export default CalendarSection;
