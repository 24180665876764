
import { awaitWrap } from "./utilTools";
import { generateNonce, generateLoginNonce } from "./api";
import { useSignMessage } from "wagmi";
import { useSnackbar } from 'notistack';

export function useGenerateNonce() {
  const { signMessageAsync } = useSignMessage();
  const { enqueueSnackbar } = useSnackbar();

  async function getGenerateNonce(
    address,
    type
  ){
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (!address) {
        reject();
        return;
      }
      // @ts-ignore
      const [nonceInfo, error] = await awaitWrap(
        type === "login" ? generateLoginNonce(address) : generateNonce(address)
      );
      if (nonceInfo !== null && nonceInfo.data?.nonce) {
        // @ts-ignore
        const [signData] = await awaitWrap(
          signMessageAsync({
            message: nonceInfo.data.nonce,
          })
        );
        if (signData) {
          resolve(signData);
        } else {
            enqueueSnackbar('Failed to obtain signature!', { variant: 'error' });
          reject(false);
        }
      } else {
        enqueueSnackbar('No account information!', { variant: 'error' });
        reject(error);
      }
    });
  }

  return {
    getGenerateNonce,
  };
}
