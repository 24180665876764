const walletInfor = {
    MetaMask: {
      name:'METAMASK'
    },
    "Coinbase Wallet": {
      name:'COINBASE'
    },
    Okexchain: {
      name:'OKX WEB3'
    },
    Truts: {
      name:'TRUST'
    },
  };
  
  const getWalletInfor = (connctor) => {
    const list = connctor.map((item) => {
      return {
        connctor: item,
        name: walletInfor[item.name].name,
      };
    });
    return list;
  };
  
  export default getWalletInfor;