import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useSpring, animated } from '@react-spring/web';

// material-ui
import { Collapse, Fade, Box, Grow, Slide, Zoom } from '@mui/material';

// ==============================|| TRANSITIONS ||============================== //

const Transitions = forwardRef(({ children, position, type, direction, ...others }, ref) => {
  let positionSX = {
    transformOrigin: '0 0 0'
  };
  const [springs, api] = useSpring(() => ({
    from: { y: -10 },
    to: { y: 0 },
    config: { duration: 200 }
  }));

  // const onEnter = () => {
  //   console.log(1, 'event onenter');
  //   api.start({
  //     from: { y: -10 },
  //     to: { y: 0 },
  //     config: { duration: 200 }
  //   });
  // };
  const onExited = () => {
    api.start({
      from: { y: 0 },
      to: { y: -10 },
      config: { duration: 200 }
    });
  };

  switch (position) {
    case 'top-right':
      positionSX = {
        transformOrigin: 'top right'
      };
      break;
    case 'top':
      positionSX = {
        transformOrigin: 'top'
      };
      break;
    case 'bottom-left':
      positionSX = {
        transformOrigin: 'bottom left'
      };
      break;
    case 'bottom-right':
      positionSX = {
        transformOrigin: 'bottom right'
      };
      break;
    case 'bottom':
      positionSX = {
        transformOrigin: 'bottom'
      };
      break;
    case 'top-left':
    default:
      positionSX = {
        transformOrigin: '10 100 0'
      };
      break;
  }

  return (
    <Box ref={ref}>
      {type === 'grow' && (
        <Grow {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Grow>
      )}
      {type === 'collapse' && (
        <Collapse {...others} sx={positionSX}>
          {children}
        </Collapse>
      )}
      {type === 'fade' && (
        <Fade
          {...others}
          timeout={{
            appear: 200,
            enter: 200,
            exit: 200
          }}
        >
          <Box sx={positionSX}>{children}</Box>
        </Fade>
      )}
      {type === 'slide' && (
        <Slide
          {...others}
          timeout={{
            appear: 0,
            enter: 400,
            exit: 200
          }}
          direction={direction}
        >
          <Box sx={positionSX}>{children}</Box>
        </Slide>
      )}
      {type === 'zoom' && (
        <Zoom {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Zoom>
      )}

      {type === '21Pops' && (
        <Fade
          {...others}
          timeout={{
            appear: 200,
            enter: 200,
            exit: 200
          }}
          // addEndListener={(e) => getListener(e)}
          // onEnter={() => onEnter()}
          onExit={() => onExited()}
        >
          <animated.div
            style={{
              positionSX,
              ...springs
            }}
          >
            {children}
          </animated.div>
        </Fade>
      )}
    </Box>
  );
});

Transitions.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['grow', 'fade', 'collapse', 'slide', 'zoom', '21Pops']),
  position: PropTypes.oneOf(['top-left', 'top-right', 'top', 'bottom-left', 'bottom-right', 'bottom']),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right'])
};

Transitions.defaultProps = {
  type: '21Pops',
  position: 'top-left',
  direction: 'up'
};

export default Transitions;
