import React from 'react';

import { Visa, applepay, Googlepay, Mastercard } from 'static/images';

import { useDispatch, useSelector } from 'react-redux';
import { IS_CASHIER, IS_BUY_CRYPTO, LOGIN_OR_RESI, SET_LOGIN_RESIGN_OPEN } from 'store/actions';

import ButtomCom from 'ui-component/extended/ButtomCom';

import './index.scss';

function Footer() {
  const token = window.localStorage.getItem('token');
  const dispatch = useDispatch();

  return (
    <div className="slide-footer">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ButtomCom
          onClick={() => {
            if (!token) return dispatch({ type: SET_LOGIN_RESIGN_OPEN, openLoginAndResgin: true });

            dispatch({ type: IS_BUY_CRYPTO, isBuyCrypto: true });
            dispatch({ type: IS_CASHIER, isCashier: true });
            gtag('event', 'click_buy_crypto', {
              type: 'left_bottom',
              isInvite: window.localStorage.getItem('invit_code') ? `ad-left_bottom` : `no-left_bottom`
            });
            if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
              gtag('event', 'bitcoinist_click_buy_crypto', {
                type: 'left_bottom'
              });
            }
          }}
          // disableBg="rgba(255, 230, 25, 0.6)"
          sx={{
            minWidth: '100%',
            height: '33px',
            fontSize: '16px',
            borderRadius: '5px',
            background: '#FFE619',
            color: '#000',
            width: '100%',
            padding: '0 20px',
            border: 'none',

            '&:hover': {
              background: '#FFEF6D',
              boxShadow: '0px 0px 6px 0px #FFE936'
            }
            // '@media (max-width: 900px)': {
            //   width: '100%'
            // }
          }}
        >
          BUY CRYPTO
        </ButtomCom>
      </div>
      <div className="item">
        <img src={Visa} alt="" />

        <img src={Mastercard} alt="" />

        <img src={applepay} alt="" />

        <img src={Googlepay} alt="" />
      </div>
    </div>
  );
}

export default Footer;
