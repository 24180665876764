import React, { useState, useRef, useEffect } from 'react';
import { Slide, DialogContent, Dialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
//utils
import { useDispatch } from 'react-redux';
//wagmi
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import CodeBox from 'ui-component/extended/WalletFormItems/codeBox';

import Transitions from 'ui-component/extended/Transitions';
import '../resetPassword/index.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

export default function AlertDialogSlide(props) {
  const { open, onClose, comfirm, email, resetCode } = props;
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [isNote, setIsNote] = useState(false);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.down('md'));
  const intervalRef = useRef(null);
  const [count, setCount] = useState(300);
  // useEffect(() => {
  //   if (open) {
  //     setCount(300);
  //   }
  //   return () => {
  //     clearInterval(intervalRef.current);
  //   };
  // }, [open]);
  // useEffect(() => {
  //   if (count === 300) {
  //     intervalRef.current = setInterval(() => {
  //       setCount((preCount) => preCount - 1);
  //     }, 1000);
  //   } else if (count === 0) {
  //     clearInterval(intervalRef.current);
  //   }
  // }, [count]);

  function close() {
    onClose();
  }

  function reSendCode() {
    resetCode();
  }
  function comfirmHandle() {
    comfirm(code);
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: matchUpMd ? '95vw' : '450px',
            // height: '250px',
            // maxHeight: '250px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div>
          <div className="dia-top">
            <div className="dia-top-arrow" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                  color: '#576788',
                  transition: 'all 0.2s ease-in',
                  '&:hover': {
                    color: '#5096FF',
                    background: 'none'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dia-top-text">VERIFICATION VIA EMAIL</div>
          </div>

          <DialogContent sx={{ background: '#151E2F', padding: '20px 64px' }}>
            <div style={{ color: '#FFFFFF', textAlign: 'center', marginBottom: '20px' }}>Please enter the code sent to {email}</div>
            <CodeBox len={6} onChange={(value) => setCode(value)} autoFocus={true}></CodeBox>
            <div style={{ color: '#fff', textAlign: 'center', margin: '20px 0', cursor: 'pointer' }} onClick={reSendCode}>{`Resend code`}</div>
            <LoadingButton
              onClick={comfirmHandle}
              disableRipple
              sx={{
                height: '40px',
                width: '180px',
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center',
                background: '#FFE619',
                fontSize: '20px',
                color: '#302B05',
                borderRadius: '5px',
                fontFamily: 'Geogrotesque Bl',
                '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
              }}
            >
              COMFIRM
            </LoadingButton>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
