import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
//utils
import getWalletInfor from 'utils/walletConfig';
import { useGenerateNonce } from 'utils/useGenerateNonce';
import { awaitWrap } from 'utils/utilTools';
import { loginByWallet } from 'store/actions/userAction';
import { useDispatch } from 'react-redux';
import { SET_ADDRESS } from 'store/actions';
import { setEvents } from 'utils/setEvents';

//wagmi
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import Transitions from 'ui-component/extended/Transitions';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

export default function AlertDialogSlide(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();

  /* eslint-disable */
  const { loading, getGenerateNonce } = useGenerateNonce();
  /* eslint-disable */
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  /* eslint-disable */
  const { connector, isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const walletInfor = getWalletInfor(connectors);
  const localAddress = window.localStorage.getItem('address');
  const token = window.localStorage.getItem('token');
  useEffect(() => {
    // if (address && !token) {
    //   signStringData(address);
    // }
    if (address) {
      // signStringData(address);
      window.localStorage.setItem('localAddress', address);
      dispatch({
        type: SET_ADDRESS,
        address: address
      });
      setEvents(2);
      onClose();
    }
  }, [isConnected]);

  useEffect(() => {
    if (localAddress) {
      onClose();
    }
  }, [localAddress]);

  async function signStringData(address) {
    let [signData, err] = await awaitWrap(getGenerateNonce(address));
    if (!signData) {
      disconnect();
      return;
    }
    loginUser(address, signData);
  }

  function loginUser(address, signature) {
    dispatch(loginByWallet({ address, signature }));
  }
  function connectWallet(item) {
    setEvents(1);
    connect({
      connector: item.connctor
    });
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid container spacing={2}>
            {walletInfor.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Button variant="contained" disableRipple color="success" sx={{ width: '100%' }} onClick={() => connectWallet(item)}>
                    {item.name}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
