import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { Input } from 'antd';
import LoadingButton from '@mui/lab/LoadingButton';
import { getBitsBd } from 'utils/api';

import { logo, verify_icon1, verify_icon2 } from 'static/images';

import Transitions from 'ui-component/extended/Transitions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    // maxWidth: '',
    width: '500px',
    // border: '1px #2F6531 solid',
    backgroundColor: '#151E2F',
    borderRadius: '30px',
    '@media(max-width: 650px)': {
      margin: '0 8px'
    }
  },
  '& .MuiDialogContent-root': {
    // padding: '16px 52px'
    padding: '16px 22px',
    paddingTop: '40px'
  },
  '& .Mui-selected': {
    color: '#013C00 !important',
    backgroundColor: '#4FB02B',
    fontSize: '20px',
    borderRadius: '5px',
    height: '40px'
  }
}));

const SecondDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    // maxWidth: '',
    width: '300px',
    // border: '1px #2F6531 solid',
    backgroundColor: '#151E2F',
    borderRadius: '30px',
    '@media(max-width: 650px)': {
      margin: '0 8px'
    }
  },
  '& .MuiDialogContent-root': {
    // padding: '16px 52px'
    padding: '16px 22px',
    paddingTop: '40px'
  },
  '& .Mui-selected': {
    color: '#013C00 !important',
    backgroundColor: '#4FB02B',
    fontSize: '20px',
    borderRadius: '5px',
    height: '40px'
  }
}));

const VerifyModel = (props) => {
  const { isOpenDialog, setIsOpenDialog } = props;
  const [accountIpt, setAccountIpt] = useState('');
  const [isTrust, setIsTrust] = useState(false);
  const [secondDialog, setSecondDialog] = useState(false);

  function handleClose() {
    setIsOpenDialog(false);
  }

  function handleIpt(val) {
    setAccountIpt(val);
  }

  async function handleSearch() {
    if (accountIpt === '') return;
    getBitsBd({ account: accountIpt })
      .then((res) => {
        setSecondDialog(true);
        setIsTrust(true);
      })
      .catch((err) => {
        setIsTrust(false);
        setSecondDialog(true);
      });
  }

  function handleClickOk() {
    setAccountIpt('');
    setSecondDialog(false);
    handleClose();
  }

  return (
    <div>
      <BootstrapDialog TransitionComponent={Transition} onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpenDialog}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#576788'
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div className="verify-model">
            <div className="model-top">
              <img src={logo} alt="logo" />
              <div className="top-title">
                <div>Official</div>
                <div>Media Authenticator</div>
              </div>
            </div>

            <div className="model-icon">
              <img src={verify_icon1} alt="icon1" />
            </div>

            <div className="model-text">
              <div className="model-text-title">
                <span style={{ color: '#2ac330' }}>21BITS</span> AGENTS AUTHENTICATOR
              </div>
              <div>
                To prevent fraud on behalf of 21BITS, You can verify the Telegram or Discord account you are having conversation with.
              </div>
              <div style={{ margin: '50px 0 20px' }}>Please type in the agents account ID, and click on check button.</div>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <Input
                size="large"
                onChange={(e) => handleIpt(e.target.value)}
                placeholder="Please enter Telegram/Discord ID"
                autoComplete="off"
                suffix={
                  <LoadingButton
                    disableRipple
                    onClick={() => handleSearch()}
                    sx={{
                      marginLeft: '1vw',
                      height: '37px',
                      minWidth: '110px',
                      background: '#0E3F13',
                      fontSize: '16px',
                      color: '#6DE036',
                      borderRadius: '5px',
                      fontWeight: '600',
                      fontFamily: `'Geogrotesque Bl', sans-serif`,
                      '&:hover': { background: '#0E3F13' }
                    }}
                  >
                    SEARCH
                  </LoadingButton>
                }
              />
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

      <SecondDialog open={secondDialog}>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div className="sec-dialog">
            <div>
              <img src={isTrust ? verify_icon1 : verify_icon2} alt="icon" />
            </div>

            {isTrust ? (
              <div className="sec-text trust">
                <span style={{ color: '#2ac330' }}>{accountIpt}</span> &nbsp; is the official representative of 21BITS. you can trust this
                account.
              </div>
            ) : (
              <div className="sec-text no-trust">
                <span style={{ color: '#2ac330' }}>{accountIpt} </span> &nbsp; is NOT the official representative of 21BITS. Do not trust
                this account.
              </div>
            )}

            <LoadingButton
              onClick={() => handleClickOk()}
              disableRipple
              sx={{
                marginLeft: '1vw',
                height: '37px',
                minWidth: '110px',
                background: '#0E3F13',
                fontSize: '16px',
                color: '#6DE036',
                borderRadius: '5px',
                fontWeight: '600',
                fontFamily: `'Geogrotesque Bl', sans-serif`,
                '&:hover': { background: '#0E3F13' }
              }}
            >
              OK
            </LoadingButton>
          </div>
        </DialogContent>
      </SecondDialog>
    </div>
  );
};

export default VerifyModel;
