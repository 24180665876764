import { marketStatis } from './api/index';

async function setEvents(event) {
  const code = window.localStorage.getItem('auth_code');
  if (!code) {
    return;
  }
  await marketStatis({
    auth_code: code,
    event
  });
}

export { setEvents };
