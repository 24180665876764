import axios from 'axios';
// import { service_api } from 'src/config';
// import useRouterTool from "utils/useRouterTool";
// axios.defaults.baseURL = service_api;
import { store } from 'store';
import { SET_GLOBAL_LOADING } from 'store/actions';

//=>Set the request header

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 30 * 1000;

let requestCount = 0;

function showLoading() {
  if (requestCount === 0) {
    // window.localStorage.setItem('isGlobalLoading', true);
    store.dispatch({ type: SET_GLOBAL_LOADING, isGlobalLoading: true });
  }
  requestCount++;
}

function hideLoading() {
  requestCount--;

  if (requestCount === 0) store.dispatch({ type: SET_GLOBAL_LOADING, isGlobalLoading: false });
  // window.localStorage.removeItem('isGlobalLoading');
}

// =>Setting up request interceptors
axios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let token = localStorage.getItem('token') || '';
      if (token) {
        config.headers['Authorization'] = token;
      }
    }
    if (config.headers.isLoading !== false) {
      showLoading();
    }

    if (config.data && typeof config.data === 'string') {
      let params = JSON.parse(config.data);
      for (const key in params) {
        if (typeof params[key] === 'string') {
          params[key] = params[key].trim();
        }
      }
      config.data = params;
    }

    if (config.data && typeof config.data === 'object') {
      for (const key in config.data) {
        if (typeof config.data[key] === 'string') {
          config.data[key] = config.data[key].trim();
        }
      }
    }

    if (process.env.NODE_ENV === 'test') {
      if (config.url.includes('/gameList')) {
        config.url = config.url.replace('/gameList', '/gameListStream');
      }
      if (config.url.includes('/gameUrl')) {
        config.url = config.url.replace('/gameUrl', '/gameUrlStream');
      }
    }

    return config;
  },
  (error) => {
    console.log('request error', config, error);
    hideLoading();
    return Promise.reject(error);
  }
);

// =>Setting up response interceptors
axios.interceptors.response.use(
  (response) => {
    if (response.config && response.config.headers.isLoading !== false) {
      hideLoading();
    }
    // return Promise.resolve(response.data);
    if (response.status === 200) {
      if (response.data) {
        if (response.data.code == '200') {
          return Promise.resolve(response.data);
        } else if (!response.data.success) {
          //alert(response.data.message);
          if (localStorage.getItem('loginType') == '1' && response.data.code === 4003) {
            // showMessage('Please go to the game to register an account', MsgStatus.warn);
            return Promise.reject(response.data);
          }
          if (response.data.code === 4003) {
            localStorage.clear();
            localStorage.clear();
            // GoHome()
            // logout()
            //   .then((res) => {
            //     localStorage.clear();
            //     localStorage.clear();
            //     // pushState("/");

            //   })
            //   .catch((e) => {
            //     if (e.code === 4003) {
            //       localStorage.clear();
            //       localStorage.clear();
            //     }
            //   });
            return Promise.reject(response.data);
          }

          return Promise.reject(response.data);
        } else {
          return Promise.resolve(response.data);
        }
      } else {
        return Promise.reject('Server busy!');
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  }
);


export { axios };
