import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import './scss/cashierModel.scss';
import Transitions from 'ui-component/extended/Transitions';
import LoadingButton from '@mui/lab/LoadingButton';
import { sendRemoteLoginCode } from 'utils/api/userSet';
import { useSnackbar } from 'notistack';

import { VECTOR } from 'static/images';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '1030px',
    width: '600px',
    border: '1px #152D43 solid',
    backgroundColor: '#151E2F',
    borderRadius: '8px',
    height: '99%',
    // margin: '0'
    '@media (max-width:1030px)': {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    '@media (max-width:800px)': {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    '@media (max-width:450px)': {
      margin: 0,
      width: '95%',
      height: '100%',
      maxHeight: '100%'
    }
  },

  '& .MuiDialogContent-root': {
    padding: '30px 52px 30px 52px',
    '@media (max-width:650px)': {
      padding: '16px 26px',
      maxHeight: '85vh'
    }
  }
  // '& .Mui-selected': {
  //   boxSizing: 'border-box',
  //   color: '#FFE619 !important',
  //   backgroundColor: '#0D131D',
  //   fontSize: '15px',
  //   borderRadius: '13px'
  // }
}));

export default function CashierModel({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const ipVerifiy = useSelector((state) => state.customization.ipVerifiy);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleColose = () => {
    onClose();
  };
  const sendEmail = async () => {
    try {
      const res = await sendRemoteLoginCode();
      enqueueSnackbar('Send success!', { variant: 'success' });
      handleColose();
    } catch (error) {}
  };
  return (
    <div>
      <BootstrapDialog open={open} TransitionComponent={Transition} onClose={handleColose} aria-describedby="alert-dialog-wallet">
        <Box
          sx={{
            position: 'relative',
            background: '#152D43',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
            fontFamily: 'Geogrotesque Bl'
          }}
        >
          LOGIN FROM UNRECOGNIZED DEVICE
        </Box>

        <DialogContent>
          <p
            style={{
              display: 'flex',
              color: '#ffe619'
            }}
          >
            <img src={VECTOR} alt="vector" style={{ width: '19px', height: '15px', marginRight: '10px' }} />
            For your account's protection, please confirm this new device via the link we sent you over email.
          </p>
          <p style={{ marginTop: '10px', paddingLeft: '30px' }}> You can continue to deposit, play, and trade in the meanwhile.</p>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              fontFamily: 'Geogrotesque Bl',
              paddingTop: '30px',
              marginTop: '10px',
              justifyContent:'space-between'
            }}
          >
            <LoadingButton
              onClick={sendEmail}
              loading={loading}
              disableRipple
              sx={{
                fontSize: '13px',
                color: '#3E3A19',
                borderRadius: '3px',
                background: '#FFE619',
                // width: '84px',
                minWidth: '50%',
                height: '2rem',
                // lineHeight: '5rem',
                padding: '0 12px',
                fontFamily: `"Geogrotesque Bl", sans-serif`,
                marginRight: '10px',
                lineHeight:'10px',
                '&:hover': {
                  backgroundColor: '#FFEF6D',
                  boxShadow: '0px 0px 6px 0px #FFE936'
                }
              }}
            >
              RESEND VERIFICATION EMAIL
            </LoadingButton>
            <LoadingButton
              onClick={handleColose}
              disableRipple
              sx={{
                fontSize: '13px',
                color: '#fff',
                borderRadius: '3px',
                background: '#152D43',
                width: '50%',
                minWidth: '50%',
                height: '2rem',
                lineHeight: '5rem',
                padding: '0 12px',
                fontFamily: `"Geogrotesque Bl", sans-serif`,
                '&:hover': {
                  backgroundColor: '#1D3E5B'
                }
              }}
            >
              KEEP PLAYING
            </LoadingButton>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
