import React, { useState, useRef, useEffect } from 'react';
import { Slide, DialogContent, Dialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
//utils
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Table } from 'antd';

//wagmi
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import Transitions from 'ui-component/extended/Transitions';
import StripeSlider from 'ui-component/stripeSlider';
import { isEmptyObject } from 'utils/utilTools';
import { toThousands } from 'utils/commonUtils';
import { getUserAccount } from 'store/actions/userAction';

import {
  claimCouponsReward,
  getUserCouponsHistoryList,
  getDepositRecordPageList,
  claimDepositActivity,
  getDepositActivityClaimList
} from 'utils/api';
import './index.scss';

import { Lock_icon, Unlock_icon } from 'static/images';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const UNCLAIM = 1;
const CLAIM = 2;

export default function FirstDeposit(props) {
  const { open, onClose, rakebackData, onReload } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.down('md'));

  const [tableLoading, setTableLoding] = useState(false);
  const [historyFlag, setHistoryFlag] = useState('');
  const [historyList, setHistoryList] = useState([]);

  const [canClaim, setCanClaim] = useState(false);

  const [openHistory, setOpenHistory] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Create Time',
      dataIndex: 'createTime',
      align: 'center'
    },
    {
      title: 'Coin Type',
      dataIndex: 'coinType',
      align: 'center'
    },
    {
      title: 'Deposit Amount',
      align: 'center',
      dataIndex: 'depositAmount'
    },
    {
      title: 'Rakeback',
      dataIndex: 'giveAmount',
      align: 'center'
    }
  ];
  const columnsClaim = [
    {
      title: 'Claim Time',
      dataIndex: 'createTime',
      align: 'center'
    },
    {
      title: 'Claim Amount',
      align: 'center',
      dataIndex: 'claimAmount'
    }
  ];
  const getHistoryList = async (flag) => {
    setTableLoding(true);
    try {
      const res = flag == 'Claim' ? await getDepositActivityClaimList({ pageIndex: 1, pageSize: 9999 }) : await getDepositRecordPageList();
      if (res.code === 200) {
        const data =flag == 'Claim' ?  res.data.records : res.data
        setHistoryList(data|| []);
        setTableLoding(false);
        setOpenHistory(true);
      }
    } catch (e) {
      setTableLoding(false);
      enqueueSnackbar(e.msg || 'error', { variant: 'error' });
    }
  };

  const handleShowHistory = (flag) => {
    setHistoryFlag(flag);
    getHistoryList(flag);
  };

  const handleClaim = async () => {
    if (!canClaim) return;
    try {
      const res = await claimDepositActivity();
      if (res.code === 200) {
        enqueueSnackbar('Success', { variant: 'success' });
        await dispatch(getUserAccount());
        onReload && onReload();
      } else {
        enqueueSnackbar(res.msg, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(e.msg || 'Error', { variant: 'error' });
    }
  };

  useEffect(() => {
    console.log(Number(rakebackData.claimAmount))
    // if (rakebackData.isClaim && Number(rakebackData.claimAmount) > 5) setCanClaim(true);
    if (Number(rakebackData.claimAmount) >= 5) setCanClaim(true);
    // else if (!rakebackData.isClaim && Number(rakebackData.claimAmount) > 0) setCanClaim(true);
    else setCanClaim(false);
  }, [rakebackData]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '540px',
            width: matchUpMd ? '95vw' : '540px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div>
          <div className="dia-top">
            <div className="dia-top-arrow" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close"
                onClick={() => onClose && onClose()}
                sx={{
                  color: '#576788',
                  transition: 'all 0.2s ease-in',
                  '&:hover': {
                    color: '#5096FF',
                    background: 'none'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dia-top-text">BONUS</div>
          </div>

          <DialogContent sx={{ background: '#151E2F', padding: '18px 30px 32px 40px' }}>
            <div>
              <div className="deposit-info">
                <div className="gift-info-left">
                  <img src={Unlock_icon} alt="" />
                  <div style={{ color: '#8299C8' }}>Unlocked</div>
                </div>
                <div>$ {toThousands(rakebackData.claimAmount)}</div>
              </div>

              <div className={classNames('yellow-btn', { 'btn-lock': !canClaim })} onClick={handleClaim}>
                Claim
              </div>
              <div className="cliam-tips">
                Minimum to claim: <span style={{ color: '#fff' }}>$ 5</span>
              </div>

              <div className="deposit-info">
                <div className="gift-info-left">
                  <img src={Lock_icon} alt="" />
                  <div style={{ color: '#8299C8' }}>Locked</div>
                </div>
                <div>$ {toThousands(rakebackData.rewardAmount)}</div>
              </div>

              <div style={{ fontFamily: 'Geogrotesque Wide' }}>
                <div style={{ color: '#8299C8', fontSize: '20px', marginBottom: '17px' }}>How to claim</div>
                <div style={{ color: '#fff', fontSize: '16px', marginBottom: '13px' }}>Unlock amount = wager amount * 1% * 25%</div>
                <div style={{ color: '#8299C8', fontSize: '16px' }}>You need to wager $2000 to reach the minimum unlock amount of $5.</div>
              </div>
            </div>

            <div className="jump-game">
              <div
                className="yellow-btn"
                onClick={() => {
                  navigate('/slots');
                  onClose && onClose();
                }}
              >
                Go to Casino
              </div>
              <div
                className="yellow-btn"
                onClick={() => {
                  navigate('/game_sports');
                  onClose && onClose();
                }}
              >
                Go to Sports
              </div>
            </div>

            <div className="history-btn" onClick={() => handleShowHistory('Rakeback')}>
              {' '}
              Rakeback History
            </div>
            <div className="history-btn" onClick={() => handleShowHistory('Claim')}>
              {' '}
              Claim History
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={openHistory}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => setOpenHistory(false)}
        aria-describedby="alert-dialog-history"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: matchUpMd ? '80vw' : '450px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div>
          <div className="dia-top">
            <div className="dia-top-arrow" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close"
                onClick={() => setOpenHistory(false)}
                sx={{
                  color: '#576788',
                  transition: 'all 0.2s ease-in',
                  '&:hover': {
                    color: '#5096FF',
                    background: 'none'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dia-top-text">{historyFlag == 'Claim' ?'CLAIM' : 'BONUS'} HISTORY</div>
          </div>

          <DialogContent sx={{ background: '#151E2F', padding: '18px 18px 32px 20px' }}>
            <div className="rakeback-history-table">
              
              <Table
                style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}
                loading={tableLoading}
                columns={historyFlag == 'Claim' ? columnsClaim : columns}
                dataSource={historyList}
                pagination={false}
                rowKey={(record) => record.createTime}
              />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
