const monthList = [
  { label: 'JAN', value: '01' },
  { label: 'FEB', value: '02' },
  { label: 'MAR', value: '03' },
  { label: 'APR', value: '04' },
  { label: 'MAY', value: '05' },
  { label: 'JUN', value: '06' },
  { label: 'JUL', value: '07' },
  { label: 'AUG', value: '08' },
  { label: 'SEPT', value: '09' },
  { label: 'OCT', value: '10' },
  { label: 'NOV', value: '11' },
  { label: 'DEC', value: '12' }
];

export const formatMonth = (date) => {
  if (!date.includes('-')) return date;
  // 2022-01-01 =>  JAN 01
  const arr = date.split('-');
  return monthList.find((item) => item.value === arr[1]).label + ' ' + arr[2];
};

export const compareDate = (today, target) => {
  const today1 = new Date(today).getTime();
  const target1 = new Date(target).getTime();
  if (today1 < target1) return 2; //after
  if (today1 > target1) return 0; // before
  return 1; // now
};

export const isTomorrow = (today, target) => {
  if (today.includes('-') && target.includes('-')) {
    const now = today.split('-')[2];
    const tomorrow = target.split('-')[2];
    if (tomorrow - now === 1) return true;
    else return false;
  } else return false;
};

export const computedCount = (arr) => {
  if (arr.length === 0) return;
  let num1;
  num1 = arr.reduce((acc, cur) => {
    if (cur.state === 1) return acc + 1;
    else return acc;
  }, 0);
  return num1 + ' / ' + arr.length;
};

export const getToday = () => {
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let day = new Date().getDate();

  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  const nowday = year + '-' + month + '-' + day;

  // setToday(nowday);
  return nowday;
};

export function countdownFn(targetDate, timeGap) {
  // targetDate = 2024-3-20 6:00:00

  targetDate = new Date(targetDate);

  const currentDate = new Date().getTime() + timeGap;

  const timeArea = 8 * 1000 * 60 * 60; // 8 hours

  var hoursRemaining = Math.floor(((targetDate - (currentDate - timeArea)) / (1000 * 60 * 60)) % 24);
  var minutesRemaining = Math.floor(((targetDate - (currentDate - timeArea)) / (1000 * 60)) % 60);
  var secondsRemaining = Math.floor(((targetDate - (currentDate - timeArea)) / 1000) % 60);

  if (secondsRemaining < 0) return false;

  const hours = hoursRemaining < 10 ? '0' + hoursRemaining : hoursRemaining;
  const mins = minutesRemaining < 10 ? '0' + minutesRemaining : minutesRemaining;
  const second = secondsRemaining < 10 ? '0' + secondsRemaining : secondsRemaining;

  // console.log(hours + ':' + mins + ':' + second);
  return hours + ':' + mins + ':' + second;
}
