import { useState, useRef, useEffect } from 'react';
import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';

import { getDepositGive, claimDepositGive, getUserCouponsTaskList, getDepositActivity } from 'utils/api';
import { getUserAccount } from 'store/actions/userAction';

import { useTheme } from '@mui/material/styles';

import Transitions from 'ui-component/extended/Transitions';

import {
  gift,
  blueBox,
  redBox,
  yellowBox,
  greenBox,
  coin,
  giftBlack,
  box1_able,
  box3_able,
  arrow_icon,
  arrow_icon_blue,
  lock_black_icon,
  vipRebits,
  vipBouns
} from 'static/images';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { IS_CASHIER, IS_COUPONS } from 'store/actions';
import { SET_UPDATE_CLAIM } from 'store/actions';

import {
  getTotalRewarded,
  getTodayClaimedReward,
  receiveClaimed,
  getReceivePercent,
  getRebateRecordFlag,
  getUserMonthWhetherClaim,
  getUserWeekWhetherClaim,
  getUserDailyWhetherClaim,
  addRebateRecord,
  addUserDayRewards,
  addUserMonthRewards,
  addUserWeeklyRewards,
  isAddVipReBits,
  addVipBonus,
  addVipReBits,
  isAddVipBonus
} from 'utils/api/reward';

import './index.scss';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { getToday } from 'utils/rewardData.js';
import BounsModel from 'ui-component/bonusModel';
import FirstDeposit from 'ui-component/firstDeposit';

const GiftSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [open, setOpen] = useState(false);
  const [bonusModel, setBonusModel] = useState(false);
  const [giveData, setGiveData] = useState({});
  const anchorRef = useRef(null);
  const [bonusData, setBonusData] = useState({});
  const [isBonusDisable, setIsBonusDisable] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const matchDown420 = useMediaQuery(theme.breakpoints.down('420'));
  const matchDown600 = useMediaQuery(theme.breakpoints.down('600'));
  const setUpdateClaim = useSelector((state) => state.customization.setUpdateClaim);

  const [isClaimDaily, setIsClaimDaily] = useState(false);
  const [isClaimWeek, setIsClaimWeek] = useState(false);
  const [isClaimMonth, setIsClaimMonth] = useState(false);
  const [isClaimRebate, setIsClaimRebate] = useState(false);
  const [isClaimVipRebit, setIsClaimVipRebit] = useState(false);
  const [isClaimVipBouns, setisClaimVipBouns] = useState(false);
  const [bonusBoxList, setBonusBoxList] = useState([]);
  const [vipBounsList, setVipBounsList] = useState([]);
  const [claimAmount, setClaimAmount] = useState(0);
  const [receivePercent, setReceivePercent] = useState({});

  const [lockAmount, setLockAmount] = useState(0);
  const [ableAmount, setAbleAmount] = useState(0);

  const [rakebackModel, setRakebackModel] = useState(false);
  const [rakebackData, setRakebackData] = useState({});
  const [hasRakeback, setHasRakeback] = useState(false);

  const boxList = [
    { img: yellowBox, label: 'RAKEBACK', disabled: isClaimRebate },
    { img: greenBox, label: 'DAILY BONUS', disabled: isClaimDaily },
    { img: redBox, label: 'WEEKLY BONUS', disabled: isClaimWeek },
    { img: blueBox, label: 'MONTHLY BONUS', disabled: isClaimMonth }
  ];

  const vipBounsListData = [
    { img: vipRebits, label: 'VIP REBITS', disabled: isClaimVipRebit },
    { img: vipBouns, label: 'VIP BONUS', disabled: isClaimVipBouns }
  ];

  const handleToggle = async () => {
    let isStream = process.env.REACT_APP_NODE_ENV === 'test' ? true : false;
    if (isStream) {
      return enqueueSnackbar('COMING SOON', { autoHideDuration: 2000, variant: 'warning' });
    }
    setOpen((prevOpen) => !prevOpen);
    if (open) return;
    try {
      getDepositGive().then((res) => {
        setGiveData(res?.data);
      });
      getAllDate();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('error', { autoHideDuration: 2000 });
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const checkClaimable = async () => {
    getRebateRecordFlag().then((res) => setIsClaimRebate(res.data));
    getUserMonthWhetherClaim().then((res) => setIsClaimMonth(res.data));
    getUserWeekWhetherClaim().then((res) => setIsClaimWeek(res.data));
    getUserDailyWhetherClaim().then((res) => setIsClaimDaily(res.data));
    isAddVipBonus().then((res) => setisClaimVipBouns(res.data.isClaim));
    isAddVipReBits().then((res) => setIsClaimVipRebit(res.data.isClaim));
  };

  const handleClaim = async () => {
    if (giveData?.state !== 0) return;
    try {
      await claimDepositGive();
      enqueueSnackbar('success', { autoHideDuration: 2000 });
      await dispatch(getUserAccount());
    } catch (err) {
      enqueueSnackbar('error', { autoHideDuration: 2000 });
    }
  };

  const handleGoBouns = () => {
    if (isBonusDisable) return;
    setOpen(false);
    setBonusModel(true);
  };

  const handleGoRakeback = () => {
    setOpen(false);
    setRakebackModel(true);
  };

  const goCoupons = () => {
    setOpen(false);
    dispatch({ type: IS_CASHIER, isCashier: true });
    dispatch({ type: IS_COUPONS, isCoupons: true });
  };

  const goDeposit = () => {
    setOpen(false);
    dispatch({ type: IS_CASHIER, isCashier: true });
  };
  const goClaim = () => {
    navigator('/game_claim');
    setOpen(false);
  };

  const handleGetRecord = async (row) => {
    console.log(row);
    if (!row.disabled) return;
    try {
      if (row.label === 'RAKEBACK') {
        await addRebateRecord();
      } else if (row.label === 'DAILY BONUS') {
        await addUserDayRewards();
      } else if (row.label === 'WEEKLY BONUS') {
        await addUserWeeklyRewards();
      } else if (row.label === 'VIP REBITS') {
        await addVipReBits();
      } else if (row.label === 'VIP BONUS') {
        await addVipBonus();
      } else {
        await addUserMonthRewards();
      }
      getAllDate();
      enqueueSnackbar('success', { variant: 'success' });
    } catch (e) {
      console.log(e, 'error');
      enqueueSnackbar(e.msg, { variant: 'error' });
    }
  };

  const getCalendarReward = async () => {
    const res = await getTodayClaimedReward();
    const today = getToday();
    const list = res.data.find((item) => item.dateTime === today);

    const claimSum = list.recordList.reduce((pre, cur) => {
      if (cur.type === 2 && cur.state === 0) {
        const dailyAmount = cur.dailyAmount ? Number(cur.dailyAmount) : 0;
        const monthlyAmount = cur.monthlyAmount ? Number(cur.monthlyAmount) : 0;
        const weeklyAmount = cur.weeklyAmount ? Number(cur.weeklyAmount) : 0;
        const rebateAmount = cur.rebateAmount ? Number(cur.rebateAmount) : 0;
        const amount = dailyAmount + monthlyAmount + weeklyAmount + rebateAmount;
        console.log('claimSum cur', amount, cur);

        return pre + Number(amount.toFixed(2));
      } else return pre;
    }, 0);
    setClaimAmount(claimSum);
  };

  const handleReceive = async () => {
    if (claimAmount === 0) return;
    try {
      await receiveClaimed({ type: 2, timeSlot: 0 });
      enqueueSnackbar('success', { variant: 'success' });
      getAllDate();
      dispatch({ type: SET_UPDATE_CLAIM, setUpdateClaim: !setUpdateClaim });
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  const getBonusData = async () => {
    try {
      const res = await getUserCouponsTaskList();
      if (res.code === 200) {
        setBonusData(res.data);
        if (res.data.coupons) {
          setIsBonusDisable(false);
        } else {
          setIsBonusDisable(true);
          return;
        }

        const taskList = res.data.couponsTaskList;

        const lock = taskList.reduce((pre, cur) => {
          if (!cur.isComplete) return pre + Number(cur.amount);
          else return pre;
        }, 0);
        const able = taskList.reduce((pre, cur) => {
          if (cur.isComplete && cur.state === 1) return pre + Number(cur.amount);
          else return pre;
        }, 0);
        setLockAmount(lock.toFixed(2));
        setAbleAmount(able.toFixed(2));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getRakebackData = async () => {
    try {
      const res = await getDepositActivity();
      if (res.code === 200) {
        setRakebackData(res.data);
        res.data.totalReward ? setHasRakeback(true) : setHasRakeback(false);
      }
    } catch (e) {}
  };

  const getReceiveCount = async () => {
    const res = await getReceivePercent();
    if (res.code === 200) {
      const data = res.data ? res.data : {};
      setReceivePercent(data);
    }
  };

  const getAllDate = () => {
    getCalendarReward();
    checkClaimable();
    getBonusData();
    getReceiveCount();
    getRakebackData();
  };

  useEffect(() => {
    console.log(isBonusDisable);
  }, [isBonusDisable]);

  const handleEnter = (e) => {
    const img = e.target.querySelector('.arrow-icon');
    console.log('img', img);
    if (img) img.src = arrow_icon_blue;
  };
  const handleLeave = (e) => {
    const img = e.target.querySelector('.arrow-icon');
    if (img) img.src = arrow_icon;
  };

  useEffect(() => {
    setBonusBoxList(boxList);
  }, [isClaimDaily, isClaimWeek, isClaimMonth, isClaimRebate]);
  useEffect(() => {
    setVipBounsList(vipBounsListData);
  }, [isClaimVipRebit, isClaimVipBouns]);

  return (
    <>
      <Box
        sx={{
          width: matchDown600 ? '30px' : '57px',
          height: '30px',
          background: matchDown600 ? 'none' : '#0A0C13',
          borderRadius: '5px',
          padding: matchDown600 ? '0px' : '0px 7px 0px 9px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: matchDown420 ? '8px' : '8px',
          marginLeft: matchesXs ? '8px' : '0',
          cursor: 'pointer',
          userSelect: 'none',
          '@media (min-width: 901px) and (max-width: 1100px)': {
            marginRight: '14px'
          }
        }}
        onClick={handleToggle}
        ref={anchorRef}
        onMouseEnter={(e) => {
          handleEnter(e);
        }}
        onMouseLeave={(e) => {
          handleLeave(e);
        }}
      >
        <img src={gift} alt="gift" style={{ width: matchDown600 ? '25px' : '21px' }} />
        {!matchDown600 && (
          <div className="arrow-class">
            <img src={arrow_icon} alt="arrow_icon" style={{ transform: `rotate(${open ? '0deg' : '180deg'})` }} className="arrow-icon" />
          </div>
        )}
      </Box>
      <Popper
        placement={'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : -60, 10]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-left'} type={'21Pops'} in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className="claim-box">
                <div className="claim-item">
                  <div className="claim-item-left">
                    <img src={coin} alt="coin" />
                    <div className="claim-item-left-text">
                      CALENDAR
                      <p className="claim-doll">Pending:$ {receivePercent?.notRewarded || 0}</p>
                    </div>
                  </div>
                  <div className="claim-item-right" style={{ background: Number(claimAmount) == 0 ? '#4E8545':'#4E8545'}} onClick={handleReceive}>
                    CLAIM $ {claimAmount}
                    {/* <p className="claim-delay">IN 00:03:36</p> */}
                  </div>
                </div>
                {vipBounsList.map((item) => (
                  <div className="claim-item" key={item.label}>
                    <div className="claim-item-left">
                      <img src={item.img} alt="img" />
                      <div className="claim-item-left-text">
                        {item.label}
                        {/* <p className="claim-doll">$100</p> */}
                      </div>
                    </div>
                    <div
                      onClick={() => handleGetRecord(item)}
                      className={classNames('claim-item-right-add', { 'btn-lock': !item.disabled })}
                    >
                      <img src={giftBlack} alt="img" />
                      ADD
                    </div>
                  </div>
                ))}
                {/* Deposit Bonus */}
                <div className="claim-item">
                  <div className="claim-item-left">
                    <img src={box3_able} alt="img" />
                    <div className="claim-item-left-text">DEPOSIT BONUS</div>
                  </div>
                  {hasRakeback ? (
                    <div onClick={() => handleGoRakeback()} className={classNames('claim-item-right-add')}>
                      <div className="bonus-btn">
                        <div>{Math.floor(Number(rakebackData.claimAmount) * 100) / 100}</div>
                        <div className="bonus-btn-right">
                          <img src={lock_black_icon} alt="" />
                          {Math.floor(Number(rakebackData.rewardAmount) * 100) / 100}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div onClick={() => goDeposit()} className={classNames('claim-item-right-add')}>
                      <img src={giftBlack} alt="img" />
                      ADD
                    </div>
                  )}
                </div>

                {/* BONUS */}
                <div className="claim-item">
                  <div className="claim-item-left">
                    <img src={box1_able} alt="img" />
                    <div className="claim-item-left-text">BONUS</div>
                  </div>
                  {!isBonusDisable ? (
                    <div onClick={() => handleGoBouns()} className={classNames('claim-item-right-add')}>
                      <div className="bonus-btn">
                        <div>{ableAmount}</div>
                        <div className="bonus-btn-right">
                          <img src={lock_black_icon} alt="" />
                          {lockAmount}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div onClick={() => goCoupons()} className={classNames('claim-item-right-add')}>
                      <img src={giftBlack} alt="img" />
                      ADD
                    </div>
                  )}
                </div>

                {bonusBoxList.map((item) => (
                  <div className="claim-item" key={item.label}>
                    <div className="claim-item-left">
                      <img src={item.img} alt="img" />
                      <div className="claim-item-left-text">
                        {item.label}
                        {/* <p className="claim-doll">$100</p> */}
                      </div>
                    </div>
                    <div
                      onClick={() => handleGetRecord(item)}
                      className={classNames('claim-item-right-add', { 'btn-lock': !item.disabled })}
                    >
                      <img src={giftBlack} alt="img" />
                      ADD
                    </div>
                  </div>
                ))}
                <div className="view-dashboard" onClick={goClaim}>
                  VIEW DASHBOARD
                </div>
              </div>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
      {!isBonusDisable && (
        <BounsModel open={bonusModel} bonusData={bonusData} onClose={() => setBonusModel(false)} onReload={() => getBonusData()} />
      )}
      {hasRakeback && (
        <FirstDeposit
          open={rakebackModel}
          rakebackData={rakebackData}
          onClose={() => setRakebackModel(false)}
          onReload={() => getRakebackData()}
        />
      )}
    </>
  );
};

export default GiftSection;
