import React from 'react';
import { Slide, DialogContent, Dialog, DialogTitle, IconButton } from '@mui/material';
//utils
import getWalletInfor from 'utils/walletConfig';
import { registerByWallet } from 'store/actions/userAction';
import { useDispatch } from 'react-redux';
import { setEvents } from 'utils/setEvents';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

//antd
import { Input, Form } from 'antd';

//wagmi
import { useAccount, useConnect } from 'wagmi';
import LoadingButton from '@mui/lab/LoadingButton';
import Transitions from 'ui-component/extended/Transitions';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

export default function AlertDialogSlide(props) {
  const { open, onClose, comfirm } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { connectAsync, connectors } = useConnect();
  const { address } = useAccount();
  const walletInfor = getWalletInfor(connectors);
  async function register(address) {
    try {
      const res = await form.validateFields();
      await dispatch(registerByWallet({ ...res, address }));
      enqueueSnackbar('Register successfully', { variant: 'success' });
      setTimeout(() => {
        comfirm();
      }, 1000);
      gtag('event', 'get_register', {
        registerType: 'wallet',
        isInvite: window.localStorage.getItem('invit_code') ? `ad-wallet` : `no-wallet`
      });
      if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
        gtag('event', 'bitcoinist_get_register', {
          type: 'wallet'
        });
      }
    } catch (error) {
      enqueueSnackbar(error.msg, { variant: 'error' });
    }
  }
  async function connectWallet(item) {
    try {
      setEvents(1);
      if (address) {
        register(address);
        return;
      }
      const res = await connectAsync({
        connector: item
      });
      register(res.account);
    } catch (error) {}
  }
  function submit() {
    const connector = walletInfor.find((item) => item.name === 'METAMASK').connctor;
    connectWallet(connector);
  }
  const customizeRequiredMark = (label, { required }) => (
    <span style={{marginBottom: '5px'}}>
      {<span style={{ fontFamily: `"Geogrotesque Wide", sans-serif` }}>{label}</span>}
      {required ? <span style={{ color: 'RGBA(239, 182, 30, 1)', marginLeft: '5px' }}> *</span> : ''}
    </span>
  );
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            height: '230px',
            maxHeight: '250px',
            borderRadius: '10px',
            '@media (max-width: 800px)': {
              width: '95vw'
            }
          }
        }}
      >
        <div style={{ height: '50px', background: '#152d43', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0px' }}>
            <IconButton
              aria-label="close"
              onClick={() => onClose && onClose()}
              sx={{
                color: '#576788',
                transition: 'all 0.2s ease-in',
                '&:hover': {
                  color: '#5096FF',
                  background: 'none'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={{
              height: '100%',
              fontSize: '20px',
              color: '#fff',
              fontFamily: 'Arial, Arial',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {'ENTER USERNAME'}
          </div>
        </div>

        <DialogContent sx={{ background: '#151E2F', padding: '20px 64px' }}>
          <Form form={form} layout="vertical" requiredMark={customizeRequiredMark}>
            <Form.Item label="USERNAME" rules={[{ required: true, message: 'Username is require' }]} name="nickName">
              <Input size="large" placeholder="USERNAME" autoComplete="off" />
            </Form.Item>
          </Form>
          <LoadingButton
            onClick={submit}
            disableRipple
            sx={{
              height: '40px',
              width: '180px',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              background: '#FFE619',
              fontSize: '20px',
              color: '#302B05',
              borderRadius: '5px',
              fontFamily: 'Geogrotesque Bl',
              '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
            }}
          >
            SUBMIT
          </LoadingButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}
