import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
//utils
import { useDispatch } from 'react-redux';

//wagmi
import { useAccount, useConnect } from 'wagmi';
import Web3 from 'web3';
import { SET_LOGIN_MODEL } from 'store/actions';
import Transitions from 'ui-component/extended/Transitions';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

const chainList = [
  {
    id: 56,
    name: 'BNB Chain'
  }
];
export default function ConnectNetwork(props) {
  const { open, onClose } = props;
  /* eslint-disable */
  const dispatch = useDispatch();
  /* eslint-disable */
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  /* eslint-disable */
  const { connector, isConnected, address } = useAccount();

  async function switchNetworks(chainId) {
    // @ts-ignore
    // const netWork =  await switchNetwork(chainId);
    window.ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: Web3.utils.numberToHex(chainId)
          }
        ]
      })
      .then((result) => {
        onClose();
        if (!address) {
          dispatch({ type: SET_LOGIN_MODEL, openLoginModel: true });
        }
      })
      .catch((error) => {
        addNetworks();
      });
  }
  async function addNetworks() {
    window.ethereum
      .request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: Web3.utils.numberToHex(56),
            chainName: 'BNB Smart Chain',
            nativeCurrency: {
              name: 'bnb',
              symbol: 'bnb',
              decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed2.binance.org'],
            blockExplorerUrls: ['https://bsc-dataseed4.binance.org']
          }
        ]
      })
      .then((res) => {
        onClose();
      })
      .catch((err) => {});
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid container spacing={2}>
            {chainList.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Button variant="contained" disableRipple color="success" sx={{ width: '100%' }} onClick={() => switchNetworks(item.id)}>
                    {item.name}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
