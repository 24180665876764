import React, { useState, useRef, useEffect } from 'react';
import { Slide, DialogContent, Dialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
//utils
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

//antd
import { Input, Form } from 'antd';

//wagmi
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

import { restPassword, checkCode, changePassword } from 'utils/api/userSet';
import CloseIcon from '@mui/icons-material/Close';

import CodeBox from 'ui-component/extended/WalletFormItems/codeBox';


import Transitions from 'ui-component/extended/Transitions';
import './index.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

export default function AlertDialogSlide(props) {
  const { open, onClose, comfirm } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [time, setTime] = useState(null);
  const timeRef = useRef();
  const [isNote, setIsNote] = useState(false);
  const [account, setAccount] = useState('');
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (time && time != 0 && step === 2) {
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else {
      setIsNote(false);
    }
    return () => {
      clearInterval(timeRef.current);
    };
  }, [time]);

  async function submit() {
    try {
      const res = await form.validateFields();
      setAccount(res.account);
      await restPassword({
        account: res.account || account,
        type: 1
      });
      enqueueSnackbar(`Send code to ${res.account}`, { variant: 'success' });
      setStep(2);
      setTime(300);
    } catch (error) {
      enqueueSnackbar(error.msg, { variant: 'error' });
    }
  }

  async function checkCodeHandle() {
    try {
      const obj = {
        account_name: account,
        auth_code: code
      };
      await checkCode(obj);
      enqueueSnackbar(`Checked success`, { variant: 'success' });
      setStep(3);
    } catch (error) {
      enqueueSnackbar(error.msg || '', { variant: 'error' });
    }
  }

  function back() {
    setStep(step - 1);
  }
  function close() {
    setStep(1);
    onClose();
  }

  function reSendCode() {
    if (isNote) {
      return;
    }
    submit();
  }

  async function comfirmHandle() {
    try {
      const res = await formPassword.validateFields();
      const obj = {
        account: account,
        verifyCode: code,
        ...res,
        
      };
      await changePassword(obj);
      setStep(1);
      onClose();
      form.resetFields();
      formPassword.resetFields();
      enqueueSnackbar(`Changed success`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.msg || '', { variant: 'error' });
    }
  }

  const customizeRequiredMark = (label, { required }) => (
    <span style={{ marginBottom: '5px' }}>
      {<span style={{ fontFamily: `"Geogrotesque Wide", sans-serif` }}>{label}</span>}
      {required ? <span style={{ color: 'RGBA(239, 182, 30, 1)', marginLeft: '5px' }}> *</span> : ''}
    </span>
  );
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: matchUpMd ? '95vw' : '450px',
            // height: '250px',
            // maxHeight: '250px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div>
          <div className="dia-top">
            <div className="dia-top-arrow" style={{ display: 'flex', justifyContent: 'space-between' }}>
              {step === 1 ? (
                <div></div>
              ) : (
                // <img src={back_one} alt="" width={20} style={{ cursor: 'pointer', visibility: 'block' }} onClick={back} />
                <div></div>
              )}
              <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                  color: '#576788',
                  transition: 'all 0.2s ease-in',
                  '&:hover': {
                    color: '#5096FF',
                    background: 'none'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dia-top-text">
              {step === 1 ? 'ENTER EMAIL' : step === 2 ? 'TWO-STEP VERIFICATION VIA EMAIL' : step === 3 ? 'ENTER NEW PASSWORD' : ''}
            </div>
          </div>

          <DialogContent sx={{ background: '#151E2F', padding: '20px 64px' }}>
            {step === 1 ? (
              <>
                <Form form={form} layout="vertical" requiredMark={customizeRequiredMark}>
                  <Form.Item label="EMAIL" rules={[{ required: true }]} name="account">
                    <Input size="large" placeholder="Email" autoComplete="off" />
                  </Form.Item>
                </Form>
                <LoadingButton
                  onClick={submit}
                  disableRipple
                  sx={{
                    height: '40px',
                    width: '180px',
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'center',
                    background: '#FFE619',
                    fontSize: '20px',
                    color: '#302B05',
                    borderRadius: '5px',
                    fontFamily: 'Geogrotesque Bl',
                    '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                  }}
                >
                  SEND EMAIL
                </LoadingButton>
              </>
            ) : step === 2 ? (
              <>
                <div style={{ color: '#FFFFFF', textAlign: 'center', marginBottom: '20px' }}>Please enter the code sent to {account}</div>
                <CodeBox len={6} onChange={(value) => setCode(value)} autoFocus={true}></CodeBox>
                <div
                  style={{ color: '#fff', textAlign: 'center', margin: '20px 0', cursor: 'pointer' }}
                  onClick={reSendCode}
                >{`Resend code ${time > 0 ? `(${time}s)` : ''}  `}</div>
                {/* <LoadingButton
                  onClick={checkCodeHandle}
                  disableRipple
                  sx={{
                    height: '40px',
                    width: '180px',
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'center',
                    background: '#FFE619',
                    fontSize: '20px',
                    color: '#302B05',
                    borderRadius: '5px',
                    fontFamily: 'Geogrotesque Bl',
                    '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                  }}
                >
                  CHECK
                </LoadingButton> */}
                <Form form={formPassword} layout="vertical" requiredMark={customizeRequiredMark}>
                  <Form.Item rules={[{ required: true }]} name="newPassword">
                    <Input.Password size="large" placeholder="New Password" autoComplete="off" />
                  </Form.Item>
                </Form>
                <LoadingButton
                  onClick={comfirmHandle}
                  disableRipple
                  sx={{
                    height: '40px',
                    width: '180px',
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'center',
                    background: '#FFE619',
                    fontSize: '20px',
                    color: '#302B05',
                    borderRadius: '5px',
                    fontFamily: 'Geogrotesque Bl',
                    '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                  }}
                >
                  COMFIRM
                </LoadingButton>
              </>
            ) : step === 3 ? (
              <></>
            ) : null}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
