import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { gif } from 'static/images';

import './index.scss';
import { useSelector } from 'react-redux';

const GlobalLoading = (props) => {
  const { children } = props;
  // const [isLoading, setIsLoading] = useState(false);
  const isLoading = useSelector((state) => state.customization.isGlobalLoading);

  // const getStorage = (e) => {
  //   console.log('loading2', e);
  //   const loading = JSON.parse(window.localStorage.getItem('isGlobalLoading')) || false;
  //   setIsLoading(loading);
  // };

  // useEffect(() => {
  //   console.log('loading1');
  //   window.addEventListener('storage', getStorage);

  //   console.log('loading3');
  //   return () => {
  //     window.removeEventListener('storage', getStorage);
  //   };
  // }, []);

  const indicator = (
    <div
      style={{
        width: '100%',
        height: '100%',

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <img src={gif} alt="" width={400} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
    </div>
  );
  return (
    <Spin spinning={isLoading} indicator={indicator} style={{}} delay={500}>
      {children}
    </Spin>
  );
};

export default GlobalLoading;
