import { Box } from '@mui/material';
import { Input } from 'antd';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';
import { ICON1 } from 'static/images';
import { claimCoupons, accountCoupons } from 'utils/api/index';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { getUserAccount } from 'store/actions/userAction';

import './scss/coupons.scss';

export default function Coupons() {
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!couponCode) return;
    try {
      setLoading(true);

      const length = couponCode.length;
      if (length === 6) await claimCoupons({ code: couponCode });
      else {
        await accountCoupons({ redeemCode: couponCode });
      }
      await dispatch(getUserAccount());
      setLoading(false);
      enqueueSnackbar('Coupons redeemed successfully.', { autoHideDuration: 2000, variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.msg, { autoHideDuration: 2000 });
      setLoading(false);
    }
  };

  const goUrl = () => {
    window.open('https://twitter.com/21bitsofficial');
  };

  const handleInput = (val) => {
    setCouponCode(val);
  };

  return (
    <Box className="coupons-box">
      <Box className="box-content">
        <Box className="box-title">REDEEM COUPON CODE</Box>
        <Input
          size="large"
          placeholder="Enter Coupon Code"
          autoComplete="off"
          value={couponCode}
          onChange={(e) => handleInput(e.target.value)}
        />
        <Box className="box-desc">
          We regularly post these on our{' '}
          <span onClick={goUrl} style={{ cursor: 'pointer' }}>
            Twitter
          </span>
        </Box>
        <LoadingButton
          loading={loading}
          disableRipple
          onClick={handleSubmit}
          className="submit-btn"
          // sx={{ '&:hover': { backgroundColor: '#ffe619' } }}
        >
          CLAIM
        </LoadingButton>
      </Box>
      <Box className="coupons-icon">
        <img src={ICON1} alt="icon" className="icon" />
      </Box>
    </Box>
  );
}
