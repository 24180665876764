import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
// import Customization from '../Customization';
import { drawerWidth } from 'store/constant';
import { SET_MENU, SET_LOGIN_MODEL, SET_NETWORK_MODEL } from 'store/actions';
// assets
import { IconChevronRight } from '@tabler/icons';
import LoginModel from 'ui-component/extended/loginModel';
import ConnectNetwork from 'ui-component/extended/connectNetwork';
import ChartLayout from '../ChartLayout';
import { useNavigate } from 'react-router-dom';
import Broadcast from 'ui-component/broadcast';
import RecordTable from 'ui-component/recordTable';
const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, sportView }) => ({
  // background: theme.palette.background.default,
  //#31363A
  height: '57px',
  justifyContent: 'center',
  background: sportView ? theme.mainBgColor.sportPath : theme.mainBgColor.default,
  zIndex: theme.zIndex.drawer,
  borderBottom: '1px solid #152032',
  boxSizing: 'border-box',
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  [theme.breakpoints.up('md')]: {
    // paddingLeft:'176px',
    // paddingRight:'176px'
  },
  [theme.breakpoints.down('950')]: {
    paddingLeft: '10px',
    paddingRight: '10px'
  }
}));
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('950')]: {
    marginLeft: !open ? drawerWidth : '44px',
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('950')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '10px',
    marginTop: '120px;'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownMdMol = useMediaQuery(theme.breakpoints.down('950'));
  const navigate = useNavigate();
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const openLoginModel = useSelector((state) => state.customization.openLoginModel);
  const openNetworkModel = useSelector((state) => state.customization.openNetworkModel);
  const isShowChat = useSelector((state) => state.customization.isShowChat);
  const sportView = useSelector((state) => state.customization.sportView);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const pathName = window.location.pathname;
  useEffect(() => {
    const mainEle = document.getElementById('mainEle');
    mainEle.scrollTo(0, 0);
  }, [pathName]);
  return (
    <Box sx={{ display: 'flex', background: '#141726' }}>
      <CssBaseline />
      {/* header */}

      {matchDownMdMol ? (
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
          }}
        >
          <Toolbar sx={{ padding: '12px 16px' }}>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
      ) : (
        <AppBarStyled theme={theme} open={leftDrawerOpened} sportView={sportView}>
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBarStyled>
      )}
      {/* drawer */}
      <Sidebar
        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
        sportView={sportView}
      />

      {/* main content */}

      <Main
        theme={theme}
        open={leftDrawerOpened}
        style={{
          minHeight: '800px',
          position: 'relative',
          // paddingRight: isShowChat ? '230px' : '0px',
          // paddingLeft: !matchDownMd ? '240px' : '0px'
        }}
        id="mainEle"
      >
        <Broadcast isShow={pathName.includes('game')} />
        {/* breadcrumb */}
        {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}

        <Outlet />
        <RecordTable isShow={pathName.includes('game')}/>
        <Footer matchDownMd={matchDownMd} />
      </Main>
      {isShowChat && <ChartLayout />}
      {/* <Customization /> */}
      <LoginModel open={openLoginModel} onClose={() => dispatch({ type: SET_LOGIN_MODEL, openLoginModel: false })} />
      <ConnectNetwork open={openNetworkModel} onClose={() => dispatch({ type: SET_NETWORK_MODEL, openNetworkModel: false })} />
    </Box>
  );
};

export default MainLayout;
