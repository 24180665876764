import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import CalendarItem from './CalendarItem';
import { Navigation } from 'swiper/modules';
import { getTodayClaimedReward, getReceivePercent, receiveClaimed } from 'utils/api/reward';
import './../index.scss';
import { getToday } from 'utils/rewardData';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { getUserAccount } from 'store/actions/userAction';

import { left_arrow, left_arrow_blue, right_arrow, right_arrow_blue } from 'static/images';
const TodayType = {
  ABLE: 0,
  LOCK: 1,
  NONE: 2
};
const PendingRewards = (props) => {
  const {
    open,
    onClose,
    // calendarData,
    totalRewarded,
    countdown,
    notRewarded,
    // today,
    // todayType,
    lockCountdown,
    // currentAbleAmount,
    handleReceive
  } = props;
  const [calendarArr, setCalendarArr] = useState([]);
  const [initSwiperIndex, setInitSwiperIndex] = useState(0);
  const [today, setToday] = useState('');
  const [currentAbleAmount, setCurrentAbleAmount] = useState(0);
  const [calendarData, setCalendarData] = useState([]);
  const [todayType, setTodayType] = useState(TodayType.LOCK);
  const [receivePercent, setReceivePercent] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleMouseEnter = (e, type) => {
    const img = e.target.querySelector('img');
    if (!img) return;

    if (type === 'left') {
      img.src = left_arrow_blue;
    } else {
      img.src = right_arrow_blue;
    }
  };
  const handleMouseLeave = (e, type) => {
    const img = e.target.querySelector('img');

    if (!img) return;
    if (type === 'left') {
      img.src = left_arrow;
    } else {
      img.src = right_arrow;
    }
  };

  useEffect(() => {
    // const arr = [];
    // for (let i = 0; i < calendarData.length; i += 30) {
    //   arr.push(calendarData.slice(i, i + 30));
    // }
    // const index = calendarData.findIndex((item) => item.dateTime === today);
    // const SwiperIndex = Math.floor(index / 30);
    // setInitSwiperIndex(SwiperIndex);
    // setCalendarArr(arr);
    // if(!open) return;
    reFalshData();
  }, [open]);
  const reFalshData = () => {
    getCalendarReward();
    getReceiveCountData();
  };
  const getReceiveCountData = async () => {
    const res = await getReceivePercent();
    if (res.code === 200) {
      const data = res.data ? res.data : {};
      setReceivePercent(data);
    }
  };
  const getCalendarReward = async () => {
    const res = await getTodayClaimedReward();
    // setCalendarData(res.data);
    const calendarData = res.data;
    const today = getToday();
    const list = res.data.find((item) => item.dateTime === today);
    const arr = [];
    for (let i = 0; i < calendarData.length; i += 30) {
      arr.push(calendarData.slice(i, i + 30));
    }
    const index = calendarData.findIndex((item) => item.dateTime === today);
    const SwiperIndex = Math.floor(index / 30);
    setInitSwiperIndex(SwiperIndex);
    setCalendarArr(arr);
    console.log(arr, 'arr');
    if (list.recordList.length > 0) computedAbleAmount(list.recordList);
    setToday(today);
  };
  const handleClamied = async () => {
    if (handleReceive) {
      await handleReceive();
      // await dispatch(getUserAccount());
      await reFalshData();
      return;
    }
    try {
      const res = await receiveClaimed();
      if (res.code === 200) {
        enqueueSnackbar('success', { variant: 'success' });
        await dispatch(getUserAccount());
        reFalshData();
      } else {
        enqueueSnackbar(res.msg, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(e.msg || 'error', { variant: 'error' });
    }
  };

  // today Amount
  const computedAbleAmount = (row) => {
    // if (!row) return;
    const ableAmountList = row.filter((item) => {
      return item.state === 0;
    });
    if (ableAmountList.length > 0) {
      setTodayType(TodayType.ABLE);
      const ableAmount = ableAmountList.reduce((pre, cur) => {
        const dailyAmount = cur.dailyAmount ? Number(cur.dailyAmount) : 0;
        const monthlyAmount = cur.monthlyAmount ? Number(cur.monthlyAmount) : 0;
        const weeklyAmount = cur.weeklyAmount ? Number(cur.weeklyAmount) : 0;
        const rebateAmount = cur.rebateAmount ? Number(cur.rebateAmount) : 0;
        const reBits = cur.reBits ? Number(cur.reBits) : 0;
        const vipBonus = cur.vipBonus ? Number(cur.vipBonus) : 0;
        const amount = dailyAmount + monthlyAmount + weeklyAmount + rebateAmount + vipBonus + reBits;
        return pre + Number(amount.toFixed(2));
      }, 0);

      setCurrentAbleAmount(ableAmount);
    } else {
      setTodayType(TodayType.NONE);
    }
  };
  return (
    <Dialog
      open={open}
      // keepMounted={false}
      // disablePortal={true}
      onClose={() => onClose && onClose()}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiPaper-root': {
          width: '1200px',
          maxWidth: '1200px',
          height: '1020px',
          background: '#151E2F',
          padding: 0,
          margin: 0,
          borderRadius: '8px'
        }
      }}
    >
      <DialogContent className="reward-dia-content">
        <div className="dia-top">
          <IconButton
            onClick={() => onClose && onClose()}
            sx={{
              color: '#576788',
              width: '12px',
              position: 'absolute',
              right: '30px',
              top: '0px',
              transition: 'all 0.2s ease-in',
              '&:hover': {
                color: '#5096FF',
                background: 'none'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dia-content-box">
          <div className="reward-top">
            <div className="reward-top-left">
              <div className="reward-pending">
                PENDING REWARDS:{' '}
                <span className="light-color" style={{ fontSize: '20px' }}>
                  $ {receivePercent.notRewarded || 0}
                </span>
              </div>
              <div className="reward-pending">
                <span style={{ whiteSpace: 'nowrap' }}>Total Claimed :</span> <span>${receivePercent.totalRewarded || 0}</span>
              </div>
            </div>
            <div className="reward-top-right">
              <div className="c-swiper-nav">
                <div
                  className={`swiper-left-prev`}
                  onMouseEnter={(e) => {
                    handleMouseEnter(e, 'left');
                  }}
                  onMouseLeave={(e) => {
                    handleMouseLeave(e, 'left');
                  }}
                >
                  <img src={left_arrow} alt="arrow" />
                </div>
                <div
                  className={`swiper-right-next`}
                  onMouseEnter={(e) => {
                    handleMouseEnter(e, 'right');
                  }}
                  onMouseLeave={(e) => {
                    handleMouseLeave(e, 'right');
                  }}
                >
                  <img src={right_arrow} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
          <div className="calendar-swiper">
            <Swiper
              modules={[Navigation]}
              navigation={{
                prevEl: `.swiper-left-prev`,
                nextEl: `.swiper-right-next`,
                disabledClass: 'disable'
              }}
              allowTouchMove={false}
              initialSlide={initSwiperIndex}
              key={initSwiperIndex}
              lazy="true"
              //   spaceBetween={5}
              //   breakpoints={{
              //     300: {
              //       slidesPerView: 1,
              //       slidesPerGroup: 1
              //     },
              //     450: {
              //       slidesPerView: 2,
              //       spaceBetween: 10,
              //       slidesPerGroup: 2
              //     },
              //     600: {
              //       slidesPerView: 4,
              //       spaceBetween: 10,

              //       slidesPerGroup: 4
              //     },
              //     900: {
              //       slidesPerView: 4,
              //       spaceBetween: 20,
              //       slidesPerGroup: 4
              //     },
              //     1100: {
              //       slidesPerView: 5,
              //       spaceBetween: 30,
              //       slidesPerGroup: 5
              //     },

              //     1300: {
              //       slidesPerView: 6,
              //       spaceBetween: 30,
              //       slidesPerGroup: 6
              //     }
              //   }}
            >
              {calendarArr.map((calendarItem, index) => (
                <SwiperSlide key={index}>
                  <div className="dia-calendar">
                    {calendarItem.map((item, index) => (
                      <div key={index}>
                        <CalendarItem
                          item={item}
                          today={today}
                          todayType={todayType}
                          // lockCountdown={lockCountdown}
                          // countdown={countdown}
                          currentAbleAmount={currentAbleAmount}
                          handleReceive={handleClamied}
                        />
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(PendingRewards);
