import { Box } from '@mui/material';
import { Input } from 'antd';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';

import { ICON2 } from 'static/images';

import { bingInviteCode } from 'utils/api/index';
import { useSnackbar } from 'notistack';
import { getUserInfor } from 'store/actions/userAction';
import { useDispatch } from 'react-redux';
import './scss/coupons.scss';

export default function Referrals() {
  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  const handleSubmit = async () => {
    if (!inviteCode) return;
    try {
      setLoading(true);
      await bingInviteCode({ invitationCode: inviteCode.trim().toLocaleLowerCase() });
      setLoading(false);
      enqueueSnackbar('success', { autoHideDuration: 2000 });
      await dispatch(getUserInfor());
    } catch (e) {
      enqueueSnackbar(e.msg || 'error', { autoHideDuration: 2000 });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo?.invitationCode) setInviteCode(userInfo.invitationCode);
  }, [userInfo?.invitationCode]);

  return (
    <Box className="coupons-box">
      <Box className="box-content">
        <Box className="box-title">APPLY REFERRAL CODE</Box>
        <Input
          size="large"
          placeholder="Enter Referral Code"
          autoComplete="off"
          value={inviteCode}
          disabled={!!userInfo?.invitationCode}
          onChange={(e) => setInviteCode(e.target.value)}
        />
        {!!userInfo?.invitationCode ? (
          <Box className="box-desc">
            You applied code: "<span>{userInfo.invitationCode}</span>"
          </Box>
        ) : (
          <Box className="box-desc">
            Don't have a code? Enter "<span>21BITS</span>"
          </Box>
        )}

        {!userInfo?.invitationCode && (
          <LoadingButton
            loading={loading}
            disableRipple
            onClick={handleSubmit}
            className="submit-btn"
            sx={{ '&:hover': { backgroundColor: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' } }}
          >
            APPLY
          </LoadingButton>
        )}
      </Box>
      <Box className="coupons-icon">
        <img src={ICON2} alt="icon" className="icon" />
      </Box>
    </Box>
  );
}
