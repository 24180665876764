import { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Progress, Divider } from 'antd';
// material-ui
import { useTheme } from '@mui/material/styles';
/* eslint-disable */
import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { useDisconnect } from 'wagmi';

// assets
import { logoutHand } from 'store/actions/userAction';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { PROFILE_ID, MENU_OPEN } from 'store/actions';
import { getVipLevel } from 'utils/getVipLevel';
import Statistics from './statistics.jsx';
import Vault from './vault';
import TIP from './tip';
//img
import {
  vip,
  vip_blue,
  Logout,
  Logout_blue,
  Vector,
  Vector_blue,
  transactions,
  transactions_blue,
  settings,
  settings_blue,
  profile,
  profile_blue,
  arrow_icon,
  arrow_icon_blue,
  statistic,
  statistic_blue,
  statistic_green,
  valutMenu,
  valutMenuBlue,
  tip,
  tip_blue
} from 'static/images';

import { useNavigate } from 'react-router-dom';

import './index.scss';
import StripeSlider from 'ui-component/stripeSlider';
// ==============================|| PROFILE MENU ||============================== //

const selectItem = [
  {
    id: '1',
    lable: 'PROFILE',
    icon: profile,
    iconOn: profile_blue,
    type: 'url'
  },
  {
    id: '10',
    lable: 'VAULT',
    icon: valutMenu,
    iconOn: valutMenuBlue,
    type: 'action'
  },
  {
    id: '2',
    lable: 'VIP',
    icon: vip,
    iconOn: vip_blue,
    type: 'url'
  },
  {
    id: '3',
    lable: 'REFERRALS',
    icon: Vector,
    iconOn: Vector_blue,
    type: 'url'
  },
  {
    id: '4',
    lable: 'TRANSACTIONS',
    icon: transactions,
    iconOn: transactions_blue,
    type: 'url'
  },
  {
    id: '6',
    lable: 'STATISTICS',
    icon: statistic,
    iconOn: statistic_blue,
    type: 'dialog'
  },
  {
    id: '7',
    lable: 'TIP',
    icon: tip,
    iconOn: tip_blue,
    type: 'dialog'
  },
  {
    id: '5',
    lable: 'SETTINGS',
    icon: settings,
    iconOn: settings_blue,
    type: 'url'
  },

  // {
  //   id: '6',
  //   lable: '21Bits Safe',
  //   icon: Safe,
  //   type: 'url'
  // },
  // {
  //   id: '7',
  //   lable: 'Live support',
  //   icon: Livesupport,
  //   type: 'url'
  // },
  {
    id: '999',
    lable: 'LOGOUT',
    icon: Logout,
    iconOn: Logout_blue,
    type: 'action'
  }
];

const ProfileSection = (props) => {
  const { token } = props;
  const theme = useTheme();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const customization = useSelector((state) => state.customization);
  const userInfor = JSON.parse(window.localStorage.getItem('userInfo'));
  const totalBetAmount = JSON.parse(window.localStorage.getItem('totalBet'));
  const { totalBet } = customization;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [levelInfo, setLevelInfor] = useState({});
  const anchorRef = useRef(null);
  const matchUpMd = useMediaQuery(theme.breakpoints.down('1200'));
  const matchUp600 = useMediaQuery(theme.breakpoints.down('600'));

  const [openStatisticDialog, setOpenStatisticDialog] = useState(false);
  const [openTipDialog, setOpenTipDialog] = useState(false);
  const [openVaultDialog, setOpenVaultDialog] = useState(false);

  const getLevelInfo = () => {
    const obj = getVipLevel(totalBetAmount);
    setLevelInfor(obj);
  };
  const selectItemHandle = async (item) => {
    try {
      if (item.id === '6') {
        setOpenStatisticDialog(true);
        setOpen(false);
        return;
      }
      if (item.id === '7') {
        setOpenTipDialog(true);
        setOpen(false);
        return;
      }
      if (item.id === '10') {
        setOpenVaultDialog(true);
        setOpen(false);
        return;
      }
      if (item.type === 'url') {
        navigate('/game_profile');
        window.localStorage.setItem('PROFILE_ID', item.id);
        dispatch({ type: PROFILE_ID, profileId: item.id });
        dispatch({ type: MENU_OPEN, id: [] });
        localStorage.setItem('MENU_ID', '');
      } else if (item.type === 'action') {
        await dispatch(logoutHand());
        disconnect();
        enqueueSnackbar('Login out success!', { variant: 'success' });
      }
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(error.msg || 'error', { variant: 'error' });
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleEnter = (e) => {
    const img = e.target.querySelector('.arrow-icon');
    if (img) img.src = arrow_icon_blue;
  };
  const handleLeave = (e) => {
    const img = e.target.querySelector('.arrow-icon');
    if (img) img.src = arrow_icon;
  };

  const handleGoProfile = () => {
    navigate('/game_profile');
    window.localStorage.setItem('PROFILE_ID', '1');
    dispatch({ type: PROFILE_ID, profileId: '1' });
    dispatch({ type: MENU_OPEN, id: [] });
    localStorage.setItem('MENU_ID', '');
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
    getLevelInfo();
  }, [open]);

  useEffect(() => {
    anchorRef.current.addEventListener('mouseenter', handleEnter);
    anchorRef.current.addEventListener('mouseleave', handleLeave);
    return () => {
      if (!anchorRef.current) return;
      anchorRef.current.removeEventListener('mouseenter', handleEnter);
      anchorRef.current.removeEventListener('mouseleave', handleLeave);
    };
  }, []);

  return (
    <>
      <div ref={anchorRef} style={{ cursor: 'pointer' }} onClick={handleToggle}>
        {/* <img
          src={`https://cravatar.cn/avatar/666.jpg?&d=retro`}
          style={{ width: '40px', borderRadius: '50%', marginRight: matchUpMd ? '5px' : '20px' }}
        /> */}
        <div className="header-account-box">
          <img src={levelInfo.levelImage} alt="" className="level-img" style={{ marginRight: '4px' }} />
          {!matchUp600 && (
            <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
              <div className="account-content ">
                <div style={{ marginBottom: '-5px' }} className="hidden-text">
                  {userInfor?.nickName || ''}
                </div>
                <div>
                  <StripeSlider percent={levelInfo.progressNum * 100} color={levelInfo.levelColor} subColor={levelInfo.subColor} />
                </div>
              </div>
              <div className="arrow-class" style={{ marginLeft: '4px' }}>
                <img
                  src={arrow_icon}
                  alt="arrow_icon"
                  style={{ transform: `rotate(${open ? '0deg' : '180deg'})` }}
                  className="arrow-icon"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} position={'top-left'} type={'21Pops'} {...TransitionProps}>
            <Paper sx={{ width: matchUp600 ? '280px' : '170px', border: '1px solid #152D43', background: '#152D43', borderRadius: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{ background: '#152D43' }}>
                  {matchUp600 ? (
                    <div style={{ padding: '14px 18px', cursor: 'pointer' }} onClick={() => handleGoProfile()}>
                      <div style={{ fontSize: '18px', fontFamily: 'Geogrotesque Bl', marginBottom: '8px', paddingLeft: '5px' }}>
                        {userInfor?.nickName || ''}
                      </div>
                      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                          <img src={levelInfo.levelImage} width="30" />
                          <div style={{ color: levelInfo?.levelColor, marginLeft: '8px', fontFamily: 'Geogrotesque Bl' }}>
                            {levelInfo?.level || ''}
                          </div>
                        </div>
                        <div style={{ width: '120px' }}>
                          <StripeSlider percent={levelInfo.progressNum * 100} color={levelInfo.levelColor} subColor={levelInfo.subColor} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', padding: '5px 10px', cursor: 'pointer' }}
                      onClick={() => handleGoProfile()}
                    >
                      <div>
                        <img src={levelInfo.levelImage} width="60" />
                      </div>
                      <div style={{ width: '100%', marginLeft: '5px' }}>
                        <div>{userInfor?.nickName || ''}</div>
                        <div>
                          <StripeSlider percent={levelInfo.progressNum * 100} color={levelInfo.levelColor} subColor={levelInfo.subColor} />
                        </div>
                      </div>
                    </div>
                  )}

                  <Box sx={{ p: 0, background: '#151E2F' }}>
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        backgroundColor: '#151E2F',
                        borderRadius: '2px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      {selectItem.map((item) => {
                        return (
                          <ListItemButton
                            key={item.id}
                            sx={{
                              borderRadius: `5px`,
                              background: '#151E2F',
                              height: matchUp600 ? '40px' : '28px',
                              pl: matchUp600 ? '25px' : '16px'
                            }}
                            selected={selectedIndex === 4}
                            onClick={() => selectItemHandle(item)}
                          >
                            {/* <ListItemIcon> */}
                            {/* <img src={item.icon} alt="icon"/> */}

                            {/* </ListItemIcon> */}
                            <ListItemText
                              sx={{}}
                              primary={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    pl: '20px',
                                    background: `url(${item.icon}) 0px 50% no-repeat`,
                                    backgroundSize: '15px 15px',
                                    color: '#576888',
                                    '&:hover': {
                                      color: '#fff',
                                      background: `url(${item.iconOn}) 0px 50% no-repeat`,
                                      backgroundSize: '15px 15px'
                                    }
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontFamily: `"Geogrotesque Bl", sans-serif`,
                                      fontSize: '13px',
                                      color: 'inherit'
                                    }}
                                  >
                                    {item.lable}
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      <Statistics open={openStatisticDialog} onClose={() => setOpenStatisticDialog(false)} />
      <TIP open={openTipDialog} onClose={() => setOpenTipDialog(false)} />
      <Vault open={openVaultDialog} onClose={() => setOpenVaultDialog(false)} />
    </>
  );
};

export default ProfileSection;
