import React, { useState, useEffect, useRef } from 'react';
// import { QRCode } from 'antd';
import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
// import '../Cashier/scss/cashierModel.scss';
import Transitions from 'ui-component/extended/Transitions';
import LoadingButton from '@mui/lab/LoadingButton';
// import {sendRemoteLoginCode} from 'utils/api/userSet'
import { useSnackbar } from 'notistack';
import { validatorQrcode, completeValidator } from 'utils/api/userSet';

import { choreplay, macos, replay, COPY } from 'static/images';

import copy from 'copy-to-clipboard';
import './index.scss';
import { parseQueryString } from 'utils/utilTools';
import CodeBox from 'ui-component/extended/WalletFormItems/codeBox';
import QRCode from 'react-qr-code';
import { getUserInfor } from 'store/actions/userAction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '1030px',
    width: '550px',
    border: '1px #152D43 solid',
    backgroundColor: '#151E2F',
    borderRadius: '8px',
    height: '99%',
    // margin: '0'
    '@media (max-width:1030px)': {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    '@media (max-width:800px)': {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    '@media (max-width:450px)': {
      margin: 0,
      width: '95%',
      height: '100%',
      maxHeight: '100%'
    }
  },

  '& .MuiDialogContent-root': {
    padding: '16px 52px 48px 52px',
    '@media (max-width:650px)': {
      padding: '16px 5px',
      maxHeight: '85vh'
    }
  }
  // '& .Mui-selected': {
  //   boxSizing: 'border-box',
  //   color: '#FFE619 !important',
  //   backgroundColor: '#0D131D',
  //   fontSize: '15px',
  //   borderRadius: '13px'
  // }
}));

export default function CashierModel({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [qrCodeStr, setQrCodeStr] = useState('');
  const [secret, setSecret] = useState('');
  const [code, setCode] = useState('');
  const ipVerifiy = useSelector((state) => state.customization.ipVerifiy);
  const token = useSelector((state) => state.customization.token);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (open) {
      get2faCode();
    }
  }, [open]);
  const get2faCode = async () => {
    try {
      const { data } = await validatorQrcode({ token });
      const stringParams = data.split('?')[1];
      const objPrarms = parseQueryString(stringParams);
      setSecret(objPrarms.ecret);
      setQrCodeStr(data);
      console.log(objPrarms);
    } catch (error) {
      console.log(error);
    }
  };
  const handleColose = () => {
    onClose();
  };
  const bind2fa = async () => {
    try {
      const params = {
        token,
        code,
        secret
      };
      const { data } = await completeValidator(params);
      if (!data) {
        enqueueSnackbar('Two-factor verification code incorrect. Please check and enter it again.', { variant: 'error' });
        return;
      }
      await dispatch(getUserInfor());
      enqueueSnackbar('Two-factor verification has been enabled for your account.', { variant: 'success' });
      handleColose();
    } catch (error) {}
  };
  const handleCopy = () => {
    copy(secret);
    enqueueSnackbar('Copied', { autoHideDuration: 2000 });
  };
  return (
    <div className="verifyfa">
      <BootstrapDialog open={open} TransitionComponent={Transition} onClose={handleColose} aria-describedby="alert-dialog-wallet">
        <DialogContent>
                
          <Box sx={{ position: 'relative', height: '50px', display: 'flex', alignItems: 'center',marginBottom: '20px' }}>
            <Box
              sx={{
                fontSize: '18px',
                fontFamily: 'Geogrotesque Bl',
              }}
            >
              Enable 2FA
            </Box>
            <IconButton
              aria-label="close"
              onClick={handleColose}
              sx={{
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#576788',
                zIndex: 2,
                transition: 'all 0.2s ease-in',
                '&:hover': {
                  color: '#5096FF'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              color: '#86979E'
            }}
          >
            Enable Two-factor Authentication to protect your account from unauthorized access.
          </Box>
          <Box
            sx={{
              background: '#152D43',
              boxSizing: 'border-box',
              padding: '16px',
              borderRadius: '8px',
              marginTop: '10px'
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <QRCode value={qrCodeStr} style={{ background: '#fff', boxSizing: 'border-box', padding: '10px', borderRadius: '5px' }} />
            </Box>
            <Box
              sx={{
                marginTop: '10px'
              }}
            >
              Scan the QR code with Google Authenticator APP
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px'
              }}
            >
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                rel="noopener"
                target="_blank"
                style={{
                  background: 'rgba(21,30,47,0.7)',
                  width: '100%',
                  marginRight: '10px',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  padding: '5px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '20px'
                  }}
                >
                  <img src={macos} alt="" srcset="" width={24} style={{ marginRight: '10px' }} />
                  <Box>
                    <Box sx={{ color: '#86979E' }}>Free Download</Box>
                    <Box>Apple Store</Box>
                  </Box>
                </Box>
                <img src={replay} alt="" srcset="" width={24} />
              </a>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                rel="noopener"
                target="_blank"
                style={{
                  background: 'rgba(21,30,47,0.7)',
                  width: '100%',
                  marginRight: '10px',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  padding: '5px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '20px'
                  }}
                >
                  <img src={choreplay} alt="" srcset="" width={24} style={{ marginRight: '10px' }} />
                  <Box>
                    <Box sx={{ color: '#86979E' }}>Free Download</Box>
                    <Box>Google Store</Box>
                  </Box>
                </Box>
                <img src={replay} alt="" srcset="" width={24} />
              </a>
            </Box>
            <Box
              sx={{
                margin: '10px 0px'
              }}
            >
              or enter the secret key manually.
            </Box>
            <Box
              className="copy-row"
              sx={{
                backgroundColor: '#0c111c',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 24px',
                borderRadius: '5px'
              }}
            >
              <div>{secret}</div>
              <img src={COPY} alt="copy" onClick={handleCopy} style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              sx={{
                marginTop: '10px'
              }}
            >
              Write down this code, never reveal it to others. You can use it to regain access to your account if there is no access to the
              authenticator.
            </Box>
          </Box>
          <Box
            sx={{
              margin: '20px 0px',
              fontSize: '18px',
              fontFamily: 'Geogrotesque Bl'
            }}
          >
            2FA Verification Code
          </Box>
          <CodeBox len={6} onChange={(value) => setCode(value)} autoFocus={true} keyDownSubmit={bind2fa}></CodeBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              fontSize: '20px',
              fontFamily: 'Geogrotesque Bl',
              marginTop: '20px'
            }}
          >
            <LoadingButton
              onClick={bind2fa}
              loading={loading}
              disableRipple
              sx={{
                fontSize: '16px',
                color: '#3E3A19',
                borderRadius: '3px',
                background: '#FFE619',
                // width: '84px',
                minWidth: '4rem',
                height: '2.5rem',
                lineHeight: '5rem',
                padding: '0 12px',
                width: '100%',
                fontFamily: `"Geogrotesque Bl", sans-serif`,
                '&:hover': {
                  backgroundColor: '#FFEF6D',
                  boxShadow: '0px 0px 6px 0px #FFE936'
                }
              }}
            >
              Enable
            </LoadingButton>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
