import { useState, useRef, useEffect } from 'react';
import './index.scss';
import { Tabs, Tab } from 'self-component/TabsSelf';
import BlackDialog from 'self-component/DialogSelf';
import { vaultDeposit, vaultWithdraw, getUserVaultAmount } from 'utils/api';
import { Input, Form, Button } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { toThousands } from 'utils/commonUtils';
import { Deposit_vault_off, Deposit_vault_on, Withdraw_vault_off, Withdraw_vault_on, Withdraw_vault_green, USDT_icon } from 'static/images';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { SET_USDT_ACCOUNT, SET_2FA_VERIFY } from 'store/actions';
import { getUserInfor } from 'store/actions/userAction';
import Tips from './tips';
const Statistics = (props) => {
  const { open, onClose } = props;
  const [depositForm] = Form.useForm();
  const [withdrawForm] = Form.useForm();
  const usdtAccount = useSelector((state) => state.customization.usdtAccount);
  const userInfor = useSelector((state) => state.customization.userInfor);
  const [tabsIndex, setTabsIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState(false);
  const [vaultAmount, setVaultAmount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserVaultAmountData();
  }, []);
  const getUserVaultAmountData = async () => {
    try {
      const res = await getUserVaultAmount();
      setVaultAmount(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubit = async (flag) => {
    setLoading(true);
    try {
      const formValue = flag == 1 ? await depositForm.validateFields() : await withdrawForm.validateFields();
      const data = {
        ...formValue,
        amount: Number(formValue.amount)
      };
      const res = flag == 1 ? await vaultDeposit(data) : await vaultWithdraw(data);
      dispatch({
        type: SET_USDT_ACCOUNT,
        usdtAccount: res.data
      });
      getUserVaultAmountData();
      enqueueSnackbar('success', { autoHideDuration: 2000, variant: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.msg) enqueueSnackbar(error.msg, { autoHideDuration: 2000, variant: 'error' });
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', flexGrow: 1 }}>
      <div>
        {
          <span
            style={{
              fontFamily: `"Geogrotesque Wide", sans-serif`,
              fontSize: '16px',
              color: '#576788',
              marginBottom: '5px'
            }}
          >
            {label}
          </span>
        }
        {required ? <span style={{ color: 'RGBA(239, 182, 30, 1)' }}> *</span> : ''}
      </div>

      {label == 'Amount' ? (
        <span
          style={{ color: 'RGBA(239, 182, 30, 1)', fontFamily: `"Geogrotesque Bl"`, marginLeft: 'auto', flexGrow: 1, textAlign: 'right' }}
        >
          ${toThousands(vaultAmount)}
        </span>
      ) : (
        ''
      )}
    </div>
  );

  const handlekeyDown = (e) => {
    if (e.key === 'Enter') return;
  };
  const handleMax = (flag) => {
    if (flag == 1) {
      depositForm.setFieldsValue({ amount: usdtAccount });
    } else {
      withdrawForm.setFieldsValue({ amount: vaultAmount });
    }
  };
  const handleTwoFa = () => {
    dispatch({ type: SET_2FA_VERIFY, verify_2fa: true });
  };
  return (
    <>
      <BlackDialog
        open={open}
        onClose={() => onClose && onClose()}
        width={540}
        title={
          <div style={{ display: 'flex', gap: '6px' }}>
            <img src={Withdraw_vault_green} style={{ width: '20px' }} />
            VAULT
          </div>
        }
      >
        <div className="vault-content">
          <div style={{ marginTop: '22px' }}>
            <Tabs headerStyle={{ margin: 0 }} isCenter={false} onChange={(index) => setTabsIndex(index)} activeIndex={tabsIndex}>
              <Tab tab="Deposit" activeIcon={Deposit_vault_on} closeIcon={Deposit_vault_off}></Tab>
              <Tab tab="Withdraw" activeIcon={Withdraw_vault_on} closeIcon={Withdraw_vault_off}></Tab>
            </Tabs>
          </div>
          {tabsIndex === 0 && (
            <div style={{ marginTop: '20px' }}>
              <div className="vault-content-box">
                <div className="vault-content-box-label">Currency</div>
                <div className="vault-content-box-value">
                  ${toThousands(usdtAccount)}{' '}
                  <span style={{ marginLeft: '5px' }}>
                    <img src={USDT_icon} alt="" srcset="" />
                  </span>
                </div>
              </div>
              <Form
                form={depositForm}
                layout="vertical"
                requiredMark={customizeRequiredMark}
                style={{ marginTop: '26px' }}
                initialValues={{ amount: '0.00' }}
              >
                <Form.Item
                  label="Amount"
                  required
                  rules={[{ required: true, message: 'amount is require' }]}
                  name="amount"
                  style={{ marginBottom: '10px' }}
                >
                  <Input
                    size="large"
                    placeholder="Please enter deposit amount"
                    autoComplete="off"
                    suffix={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={USDT_icon} alt="" srcset="" width={20} />
                        <LoadingButton
                          disableRipple
                          onClick={() => handleMax('1')}
                          sx={{
                            height: '30px',
                            width: '60px',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#FFE619',
                            fontSize: '16px',
                            color: '#302B05',
                            borderRadius: '3px',
                            fontFamily: 'Geogrotesque Bl',
                            lineHeight: '16px',
                            marginLeft: '30px',
                            '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                          }}
                        >
                          Max
                        </LoadingButton>
                      </div>
                    }
                  />
                </Form.Item>
              </Form>
              <LoadingButton
                onClick={() => handleSubit('1')}
                loading={loading}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#3E3A19',
                  borderRadius: '5px',
                  background: '#FFE619',
                  // width: '84px',
                  minWidth: '100%',
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#FFEF6D',
                    boxShadow: '0px 0px 6px 0px #FFE936'
                  }
                  // boxShadow:
                  //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                }}
              >
                Deposit to Vault
              </LoadingButton>
              <LoadingButton
                onClick={()=>setTips(true)}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#5583AE',
                  borderRadius: '5px',
                  background: '#152D43',
                  marginTop: '14px',
                  // width: '84px',
                  minWidth: '100%',
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`
                  // boxShadow:
                  //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                }}
              >
                Learn more about Vault
              </LoadingButton>
            </div>
          )}
          {tabsIndex === 1 && (
            <div style={{ marginTop: '20px' }}>
              <div className="vault-content-box">
                <div className="vault-content-box-label">Currency</div>
                <div className="vault-content-box-value">
                  ${toThousands(usdtAccount)}{' '}
                  <span style={{ marginLeft: '5px' }}>
                    <img src={USDT_icon} alt="" srcset="" />
                  </span>
                </div>
              </div>
              <Form
                form={withdrawForm}
                layout="vertical"
                requiredMark={customizeRequiredMark}
                style={{ marginTop: '26px' }}
                initialValues={{ amount: '0.00' }}
              >
                <Form.Item
                  label="Amount"
                  required
                  rules={[{ required: true, message: 'amount is require' }]}
                  name="amount"
                  style={{ marginBottom: '10px' }}
                >
                  <Input
                    size="large"
                    placeholder="Please enter withdraw amount"
                    autoComplete="off"
                    suffix={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={USDT_icon} alt="" srcset="" width={20} />
                        <LoadingButton
                          disableRipple
                          onClick={() => handleMax('2')}
                          sx={{
                            height: '30px',
                            width: '60px',
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#FFE619',
                            fontSize: '16px',
                            color: '#302B05',
                            borderRadius: '3px',
                            fontFamily: 'Geogrotesque Bl',
                            lineHeight: '16px',
                            marginLeft: '30px',
                            '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                          }}
                        >
                          Max
                        </LoadingButton>
                      </div>
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  required
                  name="password"
                  rules={[{ required: true, message: 'Password is require' }]}
                  onKeyDown={handlekeyDown}
                  style={{ marginBottom: '10px' }}
                >
                  <Input.Password
                    size="large"
                    placeholder="********"
                    autoComplete="off"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
                    }
                  />
                </Form.Item>
                {!!userInfor.twoFa && (
                  <Form.Item
                    label="Two Factor"
                    name="code"
                    rules={[{ required: true, message: 'Two factor code is require' }]}
                    onKeyDown={handlekeyDown}
                  >
                    <Input size="large" placeholder="Please enter two factor code" autoComplete="off" />
                  </Form.Item>
                )}
              </Form>
              <LoadingButton
                onClick={() => handleSubit('2')}
                loading={loading}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#3E3A19',
                  borderRadius: '5px',
                  background: '#FFE619',
                  // width: '84px',
                  minWidth: '100%',
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#FFEF6D',
                    boxShadow: '0px 0px 6px 0px #FFE936'
                  }
                  // boxShadow:
                  //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                }}
              >
                Withdraw from Vault
              </LoadingButton>
              {!!!userInfor.twoFa && (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px' }}>
                  <div style={{ color: '#5F7096', marginBottom: '10px' }}>lmprove your account security with Two-Factor Authentication</div>
                  <LoadingButton
                    onClick={handleTwoFa}
                    // loading={loading}
                    disableRipple
                    sx={{
                      fontSize: '14px',
                      color: '#5583AE',
                      borderRadius: '5px',
                      background: '#152D43',
                      // width: '84px',
                      minWidth: '100%',
                      height: '30px',
                      lineHeight: '30px',
                      fontFamily: `"Geogrotesque Bl", sans-serif`
                      // boxShadow:
                      //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                    }}
                  >
                    Enable 2FA
                  </LoadingButton>
                </div>
              )}

              <LoadingButton
                onClick={()=>setTips(true)}
                // loading={loading}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#5583AE',
                  borderRadius: '5px',
                  background: '#152D43',
                  marginTop: '14px',
                  // width: '84px',
                  minWidth: '100%',
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`
                  // boxShadow:
                  //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                }}
              >
                Learn more about Vault
              </LoadingButton>
            </div>
          )}
        </div>
      </BlackDialog>
      <Tips open={tips} onClose={() => setTips(false)} />
    </>
  );
};

export default Statistics;
