import { useState, useRef, useEffect } from 'react';
import BlackDialog from 'self-component/DialogSelf';

const Tips = (props) => {
  const { open, onClose } = props;
  return (
    <BlackDialog open={open} onClose={() => onClose && onClose()} width={540} title={<div style={{ display: 'flex', gap: '6px' }}></div>}>
      <div
        className="vault-content"
        style={{
          '&>p': {
            marginTop: '10px'
          }
        }}
      >
        <p style={{marginTop: '10px'}}>The 21Bits Vault functions as an extra security measure for storing funds. </p>
        <p style={{marginTop: '10px'}}>It allows you to safely deposit money in a secure vault, separating it from your active betting balance.</p>
        <p style={{marginTop: '10px'}}>This protects your funds from unauthorized access while still making them easily accessible when needed. </p>
        <p style={{marginTop: '10px'}}>
          This feature is available to all users of 21Bits, providing an additional layer of protection for your money while engaging in
          casino games or sports betting on the platform.{' '}
        </p>
      </div>
    </BlackDialog>
  );
};

export default Tips;
