import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';

import { WagmiConfig } from 'wagmi';
import { wagmiConfig } from './wagmi';

//swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { ConfigProvider } from 'antd';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <WagmiConfig config={wagmiConfig}>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#141726',
              colorBgContainer: '#0C111C',
              colorBorder: '#5096FF',
              colorText: '#fff',
              colorTextQuaternary: '#576788',
              colorBgContainerDisabled: '#0C111C'
            },
            components: {
              Input: {
                activeBorderColor: '#5096FF',
                activeShadow: 'none',
                fontFamily: `"Geogrotesque Wide", sans-serif`,
                colorTextPlaceholder: 'RGBA(91, 91, 91, 1)',
                errorActiveShadow: 'none',
                warningActiveShadow: 'none',
                hoverBorderColor: 'none',
                colorBorder: '#151E2F'
              },
              InputNumber: {
                activeBorderColor: '#5096FF',
                activeShadow: 'none',
                colorTextPlaceholder: 'RGBA(91, 91, 91, 1)',
                colorBorder: '#151E2F'
              },
              Select: {
                colorText: '#fff',
                controlItemBgActive: 'rgba(33, 150, 243, 0.12)', //active color
                controlItemBgHover: 'rgba(33, 150, 243, 0.12)', //hover bg color
                colorBorder: 'RGBA(29, 80, 10, 1)', //
                colorBgElevated: 'rgba(0, 0, 0)', //
                controlOutlineWidth: 0, //
                colorTextQuaternary: '#fff', //
                colorTextPlaceholder: 'RGBA(91, 91, 91, 1)', //
                controlHeight: 28
              },
              Slider: {
                railBg: '#000',
                dotActiveBorderColor: 'none'
              },
              Switch: {
                handleBg: '#000',
                colorPrimary: '#FFE619',
                colorPrimaryHover: '#FFEF6D',
                colorTextTertiary: '#919191',
                colorTextQuaternary: '#919191',
                trackHeightSM: 16,
                handleSizeSM: 12
              },
              Spin: {
                colorPrimary: '#ffe619',
              }
            }
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </WagmiConfig>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
