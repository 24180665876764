import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toThousands, getImageUrl } from 'utils/commonUtils';
import { useTheme } from '@mui/material/styles';
import { goGame } from 'utils/threeGames';
import { useMediaQuery } from '@mui/material';

import { Bets, EmptSlot } from 'static/images';

import './index.scss';
const RecordTable = ({ isShow }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const betAllRecordList = useSelector((state) => state.customization.betAllRecordList);
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const columns = [
    {
      title: 'GAME',
      dataIndex: 'game_name',
      key: 'game_name',
      render: (text, record) => {
        const { brand } = record;
        return (
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <img
              src={getImageUrl(brand, record)}
              onError={(e) => {
                e.target.src = EmptSlot;
                e.target.onerror = null;
              }}
              width={26}
              style={{ borderRadius: '3px', height: '26px' }}
            />
            <div className="table-game-name">{text}</div>
          </div>
        );
      }
    },
    {
      title: 'PLAYER/CLAN',
      dataIndex: 'name',
      key: 'name',
      hidden: matchDownMd,
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <img
              src={`https://s3.21bits.io/front_static/assets/images/vip_level/${record.lv}.png`}
              onError={(e) => {
                e.target.src = `https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_1.png`;
                e.target.onError = null;
              }}
              alt=""
              width={26}
              style={{ borderRadius: '3px', height: '26px' }}
            />
            <div style={{ fontFamily: `"Geogrotesque Bl", sans-serif`, marginLeft: '5px' }}>{text}</div>
          </div>
        );
      }
    },
    // {
    //   title: 'TIME',
    //   dataIndex: 'name',
    //   key: 'name'
    // },
    {
      title: 'WAGER',
      dataIndex: 'wager',
      key: 'wager',
      hidden: matchDownMd,
      render: (text, record) => {
        const { wager } = record.bet;
        return (
          <div style={{ marginLeft: '5px', color: '#676d7c', fontFamily: `"Geogrotesque Bl", sans-serif` }}>${toThousands(wager)}</div>
        );
      }
    },
    {
      title: 'MULTIPLIER',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const { pay_out, wager } = record.bet;
        const multi = ((pay_out * 1) / (wager * 1)).toFixed(2);
        return <div style={{ marginLeft: '5px', color: '#676d7c', fontFamily: `"Geogrotesque Bl", sans-serif` }}>{multi}x</div>;
      }
    },
    {
      title: 'PAYOUT',
      dataIndex: 'pay_out',
      key: 'pay_out',
      render: (text, record) => {
        const { pay_out, wager } = record.bet;
        return (
          <div
            style={{
              marginLeft: '5px',
              color: pay_out * 1 <= wager * 1 ? '#676d7c' : 'rgb(114, 242, 56)',
              fontFamily: `"Geogrotesque Bl", sans-serif`
            }}
          >
            ${toThousands(pay_out)}
          </div>
        );
      }
    }
  ].filter((item) => !item.hidden);

  return (
    <div className="games-table" style={{ display: isShow ? 'none' : 'block' }}>
      <div className="table-title">
        <img src={Bets} alt="games" />
        BETS
      </div>
      <Table
        style={{ minHeight: matchDownMd ? '300px' : '500px' }}
        // loading={tableLoading}
        columns={columns}
        dataSource={betAllRecordList}
        pagination={false}
        rowKey={(record) => `${record.key}`}
        onRow={(record, rowKey) => ({
          onClick: () => goGame(record, navigate)
        })}
      />
    </div>
  );
};

export default RecordTable;
