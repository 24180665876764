import { Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import './scss/deposit.scss';

import { QRCode } from 'antd';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { Input } from 'antd';
import BuyCrypto from './../WalletFormItems/BuyCrypto';
import { useNavigate } from 'react-router-dom';
import { getUserAccount } from 'store/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { PROFILE_ID, IS_BUY_CRYPTO } from 'store/actions';
import classNames from 'classnames';

import { BNB, ETH, USDT, USDC, TRUMP, SOL, BTC, OTHER_1, OTHER_2, OTHER_3, OTHER_4, VECTOR, COPY, DOLLAR, Go_back ,LTC} from 'static/images';

import {
  getSymbolPrice,
  getBtcPrice,
  getSolPrice,
  getTrumpPrice,
  synchronousSolDeposit,
  getEthPrice,
  getBNBPrice,
  getUserDepositNumber,
  getErcSymbolList,
  getEthInternalTransaction,
  getBnBInternalTransaction
} from 'utils/api';

export default function Deposit(props) {
  const { closeModel } = props;
  const [coinType, setCoinType] = useState({});
  const [isDeposit, setIsDeposit] = useState(false);
  const [coinPrice, setCoinPrice] = useState('1'); //价格比例
  const [usdIpt, setUsdIpt] = useState('100');
  const [coinIpt, setCoinIpt] = useState('');
  const [showBuyCrypto, setShowBuyCrypto] = useState(false);
  const [depositAddress, setDepositAddress] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  const [btcPrice, setBtcPrice] = useState('');
  const [ethPrice, setEthPrice] = useState('');
  const [bnbPrice, setBnbPrice] = useState('');
  const [ltcPrice, setLtcPrice] = useState('');
  const [solPrice, setSolPrice] = useState('');
  const [trumpPrice, setTrumpPrice] = useState('');

  const [isRequesting, setIsRequesting] = useState(false);

  const isBuyCrypto = useSelector((state) => state.customization.isBuyCrypto);

  const [showErcModel, setShowErcModel] = useState(false);
  const [iptCryptoName, setiptCryptoName] = useState('');
  const [cryptoList, setCryptoList] = useState([]);

  const [userDepositNumber, setUserDepositNumber] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const coinOptions = [
    { icon: BTC, symbol: 'BTC', name: 'Bitcoin', color: '#fd901b', disabled: false },
    { icon: ETH, symbol: 'ETH', name: 'Ethereum', color: '#7987CD', disabled: false },
    { icon: LTC, symbol: 'LTC', name: 'Litecoin', color: '#345D9D', disabled: false },
    { icon: SOL, symbol: 'SOL', name: 'Solana', color: '#a258e7', disabled: false },
    { icon: BNB, symbol: 'BNB', name: 'Bsc', color: '#E9B40C', disabled: false },
    { icon: USDT, symbol: 'USDT', name: 'BEP-20', chain: 'bsc', color: '#1BA27A', disabled: false },
    { name: 'ERC-20', otherChian: true, hasSelectModel: true, chain: 'erc', disabled: false }
  ];

  const ercCoin = [
    // { icon: USDT, symbol: 'USDT', chain: 'erc' },
    // { icon: USDT, symbol: 'USDT', chain: 'erc' }
  ];

  const depositNumOption = [
    { index: 0, value: '200%', amount: '10.00' },
    { index: 1, value: '240%', amount: '50.00' },
    { index: 2, value: '320%', amount: '100.00' },
    { index: 3, value: '400%', amount: '200.00' }
  ];

  const handleClickCoin = (coin) => {
    if (coin.disabled) return;
    if (coin.hasSelectModel) {
      return setShowErcModel(true);
    }
    let address;
    address = coin.symbol === 'SOL' ? userInfo?.solAddress : coin.symbol === 'BTC' ? userInfo?.btcAddress : coin.symbol === 'LTC' ? userInfo?.ltcAddress : userInfo?.rechargeAddress;
    setDepositAddress(address);
    console.log('coinType', coin);
    setCoinType(coin);
    setIsDeposit(true);
    gtag('event', 'deposit_type', {
      type: coin.symbol,
      isInvite: window.localStorage.getItem('invit_code') ? `ad-${coin.symbol}` : `no-${coin.symbol}`
    });
    if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
      gtag('event', 'bitcoinist_deposit_type', {
        type: coin.symbol
      });
    }
  };

  const handleCopy = () => {
    copy(depositAddress);
    enqueueSnackbar('Copied', { autoHideDuration: 2000 });
  };

  const handleGoBack = () => {
    setShowErcModel(false);
    setIsDeposit(false);
    setUsdIpt(100);
  };

  const handleIptCoin = (val) => {
    setCoinIpt(val);
    setUsdIpt((Number(val) * Number(coinPrice)).toFixed(2));
  };

  const handleIptUsd = (val) => {
    setUsdIpt(val);
    setCoinIpt((Number(val) / Number(coinPrice)).toFixed(4));
  };

  const handleGoPath = () => {
    closeModel && closeModel();
    navigate('/game_profile');
    window.localStorage.setItem('PROFILE_ID', '4');
    dispatch({ type: PROFILE_ID, profileId: '4' });
  };

  const updateTips = () => {
    return (
      <div style={{ fontFamily: 'Geogrotesque Wide' }}>
        <div style={{ fontSize: '16px', fontFamily: 'Geogrotesque Bl' }}>Sweep has been requested.</div>
        <div>Please be patient. Address sweeps will be processed in the order they have been received.</div>
      </div>
    );
  };

  const handleUpdate = debounce(async () => {
    setIsRequesting(true);
    if (coinType.symbol === 'SOL') {
      await synchronousSolDeposit({ solAddress: depositAddress });
    }
    if (coinType.symbol === 'ETH' || coinType.chain === 'erc') {
      await getEthInternalTransaction();
    }
    if (coinType.symbol === 'BNB' || coinType.symbol === 'USDT') {
      await getBnBInternalTransaction();
    }
    await dispatch(getUserAccount());
    setIsRequesting(false);

    // enqueueSnackbar('success', { autoHideDuration: 2000, variant: 'success' });
    enqueueSnackbar(updateTips(), { autoHideDuration: 3000, variant: 'success' });
  }, 500);

  const handleCloseBuyCrypto = (flag) => {
    setShowBuyCrypto(flag);
    dispatch({ type: IS_BUY_CRYPTO, isBuyCrypto: flag });
    gtag('event', 'deposit_type', {
      type: 'Buy Crypto',
      isInvite: window.localStorage.getItem('invit_code') ? `ad-Buy Crypto` : `no-Buy Crypto`
    });
    if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
      gtag('event', 'bitcoinist_deposit_type', {
        type: 'Buy Crypto'
      });
    }
  };
  useEffect(() => {
    if (coinType.symbol === 'BTC') setCoinPrice(btcPrice);
    if (coinType.symbol === 'BNB') setCoinPrice(bnbPrice);
    if (coinType.symbol === 'ETH') setCoinPrice(ethPrice);
    if (coinType.symbol === 'SOL') setCoinPrice(solPrice);
    if (coinType.symbol === 'TRUMP') setCoinPrice(trumpPrice);
    if (coinType.symbol === 'LTC') setCoinPrice(ltcPrice);
    if (coinType.symbol === 'USDT') setCoinPrice(1);
    if (coinType.symbol === 'USDC') setCoinPrice(1);
  }, [coinType]);

  useEffect(() => {
    if (coinPrice) setCoinIpt((Number(usdIpt) / Number(coinPrice)).toFixed(4));
  }, [coinPrice]);

  useEffect(() => {
    setShowBuyCrypto(isBuyCrypto);
  }, [isBuyCrypto]);

  useEffect(() => {
    getSymbolPrice().then((res) => {
      if (res.code !== 200) return enqueueSnackbar(res.msg, { autoHideDuration: 2000, variant: 'error' });
      let prices = res.data.prices;
      prices.map((item) => {
        if (item.symbol === 'BTCUSDT') {
          setBtcPrice(item.price);
          sessionStorage.setItem('SET_BTC_PRICE', item.price);
        }
        if (item.symbol === 'SOLUSDT') {
          setSolPrice(item.price);
          sessionStorage.setItem('SET_SOL_PRICE', item.price);
        }
        if (item.symbol === 'ETHUSDT') {
          setEthPrice(item.price);
          sessionStorage.setItem('SET_ETH_PRICE', item.price);
        }
        if (item.symbol === 'TRUMPUSDT') {
          setTrumpPrice(item.price);
          sessionStorage.setItem('SET_TEUMP_PRICE', item.price);
        }
        if (item.symbol === 'BNBUSDT') {
          setBnbPrice(item.price);
          sessionStorage.setItem('SET_BNB_PRICE', item.price);
        }
        if (item.symbol === 'LTCUSDT') {
          setLtcPrice(item.price);
          sessionStorage.setItem('SET_LTC_PRICE', item.price);
        }
      });
    });

    getUserDepositNumber().then((res) => {
      if (res.code === 200 && res.data !== null) {
        setUserDepositNumber(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (showErcModel) {
      getErcSymbolList().then((res) => {
        if (res.code === 200) {
          console.log(res);
          const arr = res.data.map((item) => {
            return { icon: item.image, chain: 'erc', disabled: false, name: item.symbol, title: item.symbol, ...item };
          });
          setCryptoList(arr);
        }
      });
    }
  }, [showErcModel]);

  return (
    <Box className="deposit-box">
      {!isDeposit && !showBuyCrypto && !showErcModel && (
        <Box>
          <Box className="title">DEPOSIT OPTIONS</Box>
          <Box className="deposit-card-box">
            {coinOptions.map((item) =>
              item.otherChian ? (
                <Box key={item.name} className="deposit-item" onClick={() => handleClickCoin(item)}>
                  <div style={{ display: 'flex', gap: '6px' }}>
                    <img src={USDT} alt="" className="deposit-icon" />
                    <img src={USDC} alt="" className="deposit-icon" />
                    <img src={TRUMP} alt="" className="deposit-icon" />
                  </div>
                  <Box sx={{ color: item?.color }} className="deposit-name">
                    {item.name}
                  </Box>
                  <Box className="deposit-symbol">{'[USDx, and more]'}</Box>
                </Box>
              ) : (
                <Box key={item.name} className="deposit-item" onClick={() => handleClickCoin(item)}>
                  <img src={item.icon} alt={item.name} className="deposit-icon" />
                  <Box sx={{ color: item.color }} className="deposit-name">
                    {item.name}
                  </Box>
                  <Box className="deposit-symbol">{item.symbol}</Box>
                  {item.disabled && <Box className="disabled"></Box>}
                </Box>
              )
            )}
            {/* 
            <Box className="deposit-item" onClick={() => handleClickCoin(ercCoin[0])}>
              <Box className="" sx={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
                {ercCoin.map((item, index) => (
                  <img src={item.icon} key={index} alt="" className="deposit-icon" />
                ))}
              </Box>
              <Box sx={{ color: '' }} className="deposit-name">
                ERC-20
              </Box>
              <Box className="deposit-symbol">[USDx, and more]</Box>
            </Box> */}

            <Box className="deposit-item" onClick={() => handleCloseBuyCrypto(true)}>
              <Box className="other-box">
                <img src={OTHER_1} alt="other" className="other-icon" />
                <img src={OTHER_2} alt="other" className="other-icon" />
                <img src={OTHER_3} alt="other" className="other-icon" />
                <img src={OTHER_4} alt="other" className="other-icon" />
              </Box>
              <Box className="deposit-symbol">Buy Crypto</Box>
            </Box>
            {/* <Box className="deposit-item" style={{ justifyContent: 'center' }}>
              <img src={MORE} alt="more" />
            </Box> */}
          </Box>
        </Box>
      )}

      {!isDeposit && !showBuyCrypto && showErcModel && (
        <Box className="deposit-content">
          <Box className="content-title">
            <img src={Go_back} alt="goBack" className="go-back" onClick={handleGoBack} />
            <Box className="title-name">DEPOSIT ERC-20 TOKENS</Box>
          </Box>

          <Box>
            {/* <Input
              size="large"
              placeholder="Search crypto"
              autoComplete="off"
              value={iptCryptoName}
              onChange={(e) => setiptCryptoName(e.target.value)}
            /> */}

            <Box sx={{ mt: '24px', mb: '30px' }}>
              <div className={classNames('crypto-list', 'crypto-header')}>
                <div className={classNames('crypto-list-crypto')}>CRYPTO</div>
                <div className="crypto-list-price">PRICE</div>
                {/* <div className="crypto-list-updown">PRICE 24H %</div> */}
              </div>
              {cryptoList.map((item, index) => (
                <div key={index} className={classNames('crypto-list')} onClick={() => handleClickCoin(item)}>
                  <div className={classNames('crypto-list-crypto')}>
                    <img src={item.icon} alt={item.name} style={{ width: '20px', marginRight: '6px' }} />
                    {item.symbol}
                  </div>
                  <div className="crypto-list-price">$ {Number(item.price).toFixed(4)}</div>
                  {/* <div className="crypto-list-updown">{item.increaseInPrice} %</div> */}
                </div>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {isDeposit && !showBuyCrypto && (
        <Box className="deposit-content">
          <Box className="content-title">
            <img src={Go_back} alt="goBack" className="go-back" onClick={handleGoBack} />
            <img src={coinType.icon} alt="coin" className="title-icon" />
            <Box className="title-name">DEPOSIT {coinType.symbol.toUpperCase()}</Box>
            <Box className="title-desc" onClick={handleGoPath}>
              View Transactions
            </Box>
          </Box>
          <Box className="content-qr">
            <Box className="qr-box">
              <Box>
                <Box className="qr-desc">
                  Send the amount of {coinType.symbol} of your choice to the following address to receive the equivalent in Coins.
                </Box>
                {coinType.symbol === 'BTC' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" /> For BTC deposits, a minimum of three block confirmations is required before the funds
                    can be successfully credited to your account. Please wait patiently during this verification process.
                  </Box>
                )}
                {coinType.symbol === 'BNB' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" /> Only deposit over the BNB network. Do not use other networks
                  </Box>
                )}
                {coinType.chain === 'erc' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" />
                    Only deposit over the Ethereum network. Do not use BSC/BNB, Base, Arbitrum or Optimism networks.
                  </Box>
                )}
                {coinType.symbol === 'USDT' && coinType.chain === 'bsc' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" />
                    Only deposit over the Binance Smart Chain network. Do not use other networks
                  </Box>
                )}

                {coinType.symbol === 'SOL' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" /> Only deposit over the SOL network. Do not use other networks
                  </Box>
                )}
                {coinType.symbol === 'ETH' && (
                  <Box>
                    <Box className="tips">
                      <img src={VECTOR} alt="vector" /> Only deposit over the Ethereum network. Do not use BNB or BSC networks
                    </Box>
                    <Box className="tips">
                      <img src={VECTOR} alt="vector" /> Do not send NFT’s to this ETH deposit address. In order to recover NFTs deposited to
                      this addresss an administrative fee will be charged.
                    </Box>
                  </Box>
                )}
              </Box>
              <div className="qr-code">
                <QRCode value={depositAddress || '-'} bgColor="#fff" color="#000" size={120} />
              </div>
            </Box>
            <Box className="copy-box">
              <Box sx={{ mb: '8px' }} className="copy-label">
                YOUR PERSONAL {coinType.symbol} DEPOSIT ADDRESS
              </Box>
              <Box className="copy-row">
                <div>{depositAddress}</div>
                <img src={COPY} alt="copy" onClick={handleCopy} />
              </Box>
            </Box>
            {coinType.symbol !== 'BTC' && (
              <Box className="deposit-tips" style={{ marginTop: '8px' }}>
                <span style={{ color: '#8299C8', fontWeight: '400' }}>Missing contract {coinType.symbol} deposit?</span>
                <span style={{ color: '#FFE619', marginleft: '4px' }} className="text-underline" onClick={handleUpdate}>
                  {isRequesting ? 'Requesting sweep...' : 'Request deposit address sweep'}
                </span>
              </Box>
            )}
            {/* {coinType.symbol === 'ETH' && (
              <Box className="deposit-tips">
                <span style={{ color: '#46E500' }}>Missing contract ETH deposit?</span>
                <span style={{ color: '#C012B9' }}> Request deposit address sweep</span>
              </Box>
            )} */}
          </Box>
          <Box className="obversion-box">
            {userDepositNumber < 4 && (
              <Box className="deposit-tips" style={{ marginBottom: '8px' }}>
                <span style={{ color: '#8299C8', fontWeight: '400', cursor: 'auto' }}>
                  Get extra <span style={{ color: '#fff' }}>{depositNumOption[userDepositNumber].value} </span>bonus on minimum of{' '}
                  <span style={{ color: '#fff', cursor: 'auto' }}>{depositNumOption[userDepositNumber].amount} USDT</span> deposit.
                </span>
              </Box>
            )}

            <Box className="obversion">
              <Input
                size="large"
                autoComplete="off"
                value={usdIpt}
                onChange={(e) => handleIptUsd(e.target.value)}
                prefix={
                  <div className="flex-box">
                    <img src={DOLLAR} style={{ width: '20px', height: '20px' }} alt="icon" />
                    <Box sx={{ ml: '8px' }}>$</Box>
                  </div>
                }
              />
              <Box sx={{ margin: '0 12px', fontSize: '20px', lineHeight: '24px' }}>=</Box>
              <Input
                size="large"
                autoComplete="off"
                value={coinIpt}
                onChange={(e) => handleIptCoin(e.target.value)}
                prefix={
                  <div className="flex-box">
                    <img src={coinType.icon} style={{ width: '20px', height: '20px' }} alt="icon" />
                  </div>
                }
              />
            </Box>
            <Box className="obversion-desc">The value of {coinType.symbol} may change between now and the time we receive your payment</Box>
          </Box>

          <Box className="help-box">
            <Box className="help-left">
              Don't have any Bitcoin?{' '}
              <Button
                disableRipple
                onClick={() => handleCloseBuyCrypto(true)}
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#302B05',
                  borderRadius: '5px',
                  background: '#FFE619',
                  width: '120px',
                  height: '32px',
                  marginLeft: '30px',
                  lineHeight: '32px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#FFEF6D',
                    boxShadow: '0px 0px 6px 0px #FFE936'
                  }
                }}
              >
                BUY CRYPTO
              </Button>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <img src={OTHER_1} alt="crypto" className="crypto-icon" />
              <img src={OTHER_2} alt="crypto" className="crypto-icon" />
              <img src={OTHER_3} alt="crypto" className="crypto-icon" />
              <img src={OTHER_4} alt="crypto" className="crypto-icon" />
            </Box>
          </Box>
        </Box>
      )}

      {showBuyCrypto && <BuyCrypto goBack={() => handleCloseBuyCrypto(false)} />}
    </Box>
  );
}
