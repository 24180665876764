import './index.scss';

const StripeSlider = (props) => {
  const { percent = 0, color = '#99D206', subColor = '#468806', background = '#141726' } = props;

  return (
    <div className="container-progress" style={{}}>
      <div className="type-box">
        <div className="type-progress" style={{ background: `${background}` }}>
          <div
            className="type-percent"
            style={{
              width: `${percent}%`,
              background: `linear-gradient(to right bottom, ${subColor} 0%, ${color} 25%, ${color}  50%,  ${subColor}  50%, ${color} 75%) 0 0 /12px
                12px`
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default StripeSlider;
