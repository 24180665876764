import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Button, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import GiftSection from './GiftSection';
import JumpSection from './jumpSection';
import CalendarSection from './CalendarSection';
// assets
import { IconMenu2 } from '@tabler/icons';
//compoent
import { useDispatch, useSelector } from 'react-redux';

import { BNB, USDT, USDC, TRUMP, ETH, BTC, SOL, lock, WALLET, chatIcon } from 'static/images';

import CashierModel from 'ui-component/extended/Cashier/CashierModel';
import {
  SET_LOGIN_RESIGN_OPEN,
  LOGIN_OR_RESI,
  IS_BUY_CRYPTO,
  IS_CASHIER,
  SHOW_CHAT,
  SHOW_NOTICE,
  SHOW_NOTICE_CONTENT,
  SET_IP_VERIFIY
} from 'store/actions';
import { getUserRealName } from 'utils/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { toThousands } from 'utils/commonUtils';
import { useLocation } from 'react-router';
import { getUserAccount } from 'store/actions/userAction';
import { debounce, throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { isObjectEmpty } from 'utils/commonUtils';
const coinMap = {
  ETH: ETH,
  BNB: BNB,
  USDT: USDT,
  BTC: BTC,
  SOL: SOL,
  'USDC-ERC20': USDC,
  'USDT-ERC20': USDT,
  TRUMP: TRUMP
};

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  // const address = useSelector((state) => state.customization.address);
  const token = window.localStorage.getItem('token');
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownMobile = useMediaQuery(theme.breakpoints.down('1250'));
  const matchDownMobileJump = useMediaQuery(theme.breakpoints.down('950'));
  const [loading, setLoading] = useState(false);
  const usdtAccount = useSelector((state) => state.customization.usdtAccount);
  const userInfor = useSelector((state) => state.customization.userInfor);
  const isShowChat = useSelector((state) => state.customization.isShowChat);
  const [hideAccount, sethideAccount] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const accountRef = useRef();
  const dispatch = useDispatch();
  function login(flag) {
    dispatch({ type: LOGIN_OR_RESI, typeLogin: flag });
    dispatch({ type: SET_LOGIN_RESIGN_OPEN, openLoginAndResgin: true });

    // return
    // if (parseInt(window.ethereum.chainId) != 56) {
    //   dispatch({ type: SET_NETWORK_MODEL, openNetworkModel: true })
    //   return
    // }
    // console.log(11111111111)
    // dispatch({ type: SET_LOGIN_MODEL, openLoginModel: true })
  }

  const handleCashier = async () => {
    dispatch({ type: IS_CASHIER, isCashier: true });
    // fbq('trackCustom', 'click_top_cashier');
    gtag('event', 'click_top_cashier', {
      type: 'cashier',
      isInvite: window.localStorage.getItem('invit_code') ? `ad-cashier` : `no-cashier`
    });
    if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
      gtag('event', 'bitcoinist_click_top_cashier', {
        type: 'cashier'
      });
    }
  };

  const handleBuyCrypto = () => {
    // setWalletVisible(true);
    dispatch({ type: IS_BUY_CRYPTO, isBuyCrypto: true });
    dispatch({ type: IS_CASHIER, isCashier: true });
    gtag('event', 'click_buy_crypto', {
      type: 'top',
      isInvite: window.localStorage.getItem('invit_code') ? `ad-top` : `no-top`
    });
    if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
      gtag('event', 'bitcoinist_click_buy_crypto', {
        type: 'top'
      });
    }
  };

  const handleShowAccount = () => {
    if (pathname !== '/third-game' && pathname !== '/game_sports' && pathname !== '/game_esports') return;
    sethideAccount(false);
    // triggerRequest();
    // dispatch(getUserAccount());
  };
  const triggerRequest = () => {
    if (pathname !== '/third-game' && pathname !== '/game_sports' && pathname !== '/game_esports') return;
    dispatch(getUserAccount());
  };

  const handleHideAccount = () => {
    if (pathname !== '/third-game' && pathname !== '/game_sports' && pathname !== '/game_esports') return;
    sethideAccount(true);
  };
  const showChat = () => {
    dispatch({ type: SHOW_CHAT, isShowChat: true });
  };

  const accountTips = (res) => {
    return (
      <div style={{ display: 'flex' }}>
        <img src={coinMap[res.symbol] || USDT} alt="" style={{ width: '20px', marginRight: '6px' }} />
        <div>${toThousands(res.amount)} was deposited into your account balance</div>
      </div>
    );
  };
  const ipVerifyModel = () => {
    dispatch({ type: SET_IP_VERIFIY, ipVerifiy: true });
  };
  useEffect(() => {
    if (accountRef.current) {
      accountRef.current.addEventListener('mouseenter', handleShowAccount);
      accountRef.current.addEventListener('mouseenter', throttle(triggerRequest, 5000));
      accountRef.current.addEventListener('mouseleave', handleHideAccount);
    }
    return () => {
      if (accountRef.current) {
        accountRef.current.removeEventListener('mouseenter', handleShowAccount);
        accountRef.current.removeEventListener('mouseenter', throttle(triggerRequest, 5000));
        accountRef.current.removeEventListener('mouseleave', handleHideAccount);
      }
    };
  }, [pathname, accountRef.current]);

  useEffect(() => {
    if (pathname === '/third-game' || pathname === '/game_sports' || pathname === '/game_esports') {
      sethideAccount(true);
    } else {
      sethideAccount(false);
    }
  }, [pathname]);
  useEffect(() => {
    if (!token) {
      return;
    }
    try {
      starx.on('cmd_kick', (res) => {
        if (res.uid) {
          window.localStorage.clear();
          navigate('/home');
        }
      });
      starx.on('cmd_broadcast', (res) => {
        dispatch({ type: SHOW_NOTICE, noticeOpen: true });
        dispatch({ type: SHOW_NOTICE_CONTENT, noticeContent: res.content });
      });
      starx.on('cmd_recharge_notify', (res) => {
        console.log('cmd_recharge_notify', res);
        enqueueSnackbar(accountTips(res), {
          variant: 'success',
          autoHideDuration: 5000
          // persist: true
        });
        dispatch(getUserAccount());
      });
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}>
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            {/* logo & toggler button */}
            <Box
              sx={{
                width: 228,
                [theme.breakpoints.down('950')]: {
                  width: 'auto'
                }
              }}
            >
              <Box component="span" sx={{ display: 'block', flexGrow: 1 }}>
                <LogoSection />
              </Box>
            </Box>

            {!matchDownMobileJump && <JumpSection />}
          </Box>

          {/* header search */}
          {/* <SearchSection /> */}
          {token && (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: matchDownMobile ? 'flex-end' : 'flex-start',
                '@media (min-width: 1250px)': {
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }
              }}
            >
              <Box
                ref={accountRef}
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#eee',
                  borderRadius: '5px',
                  bgcolor: '#0A0C13',
                  minWidth: 80,
                  padding: '0px 14px',
                  height: matchDownMd ? '40px' : '30px',
                  lineHeight: matchDownMd ? '40px' : '30px',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center'
                }}
                // onMouseEnter={handleShowAccount}
                // // onClick={handleTrigger}
                // onMouseLeave={handleHideAccount}
              >
                {!isObjectEmpty(userInfor) && userInfor.remoteLogin == 1 && (
                  <img src={lock} style={{ width: '16px', marginRight: '5px', cursor: 'pointer' }} onClick={ipVerifyModel} />
                )}

                {hideAccount ? 'Playing' : `$ ${toThousands(Number(usdtAccount).toFixed(2)) || 0.0}`}
              </Box>

              <LoadingButton
                onClick={handleCashier}
                loading={loading}
                disableRipple
                sx={{
                  fontSize: '13px',
                  color: '#3E3A19',
                  borderRadius: matchDownMd ? '0 5px 5px 0' : '5px',
                  background: '#FFE619',
                  // width: '84px',
                  minWidth: matchDownMd ? '40px' : '64px',
                  height: matchDownMd ? '40px' : '30px',
                  lineHeight: matchDownMd ? '40px' : '30px',
                  margin: matchDownMd ? '0' : '0 12px',
                  padding: '0 12px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#FFEF6D',
                    boxShadow: '0px 0px 6px 0px #FFE936'
                  }
                  // boxShadow:
                  //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                }}
              >
                {!matchDownMobile ? 'CASHIER' : <img src={WALLET} alt="Cashier" />}
              </LoadingButton>

              <Button
                onClick={handleBuyCrypto}
                disableRipple
                sx={{
                  display: matchDownMobile ? 'none' : '',
                  fontSize: '13px',
                  // fontWeight: 'bold',
                  color: '#fff',
                  borderRadius: '5px',
                  background: '#152D43',
                  width: '120px',
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#1D3E5B'
                  }
                }}
              >
                BUY CRYPTO
              </Button>
            </Box>
          )}

          {/* notification & profile */}
          {/* <NotificationSection /> */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {token && <CalendarSection />}
            {token && <GiftSection />}
            {token ? (
              <ProfileSection token={token} />
            ) : (
              <>
                <Box>
                  <Button
                    disableRipple
                    onClick={() => login('login')}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#fff',
                      borderRadius: '5px',
                      background: '#141726',
                      width: '84px',
                      height: '30px',
                      lineHeight: '31px',
                      fontFamily: `"Geogrotesque Bl", sans-serif`,
                      marginRight: '10px',
                      '&:hover': {
                        // backgroundColor: '#000'
                      }
                    }}
                  >
                    LOGIN
                  </Button>
                  <Button
                    disableRipple
                    onClick={() => login('resigin')}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#000000',
                      borderRadius: '5px',
                      background: '#FFE619',
                      width: '84px',
                      height: '31px',
                      lineHeight: '31px',
                      fontFamily: `"Geogrotesque Bl", sans-serif`,
                      '&:hover': {
                        backgroundColor: '#FFEF6D',
                        boxShadow: '0px 0px 6px 0px #FFE936'
                      }
                    }}
                  >
                    REGISTER
                  </Button>
                </Box>
              </>
            )}
            {false && (
              <div style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={showChat}>
                <img src={chatIcon} alt="logo" width={30} />
              </div>
            )}
          </Box>
        </Box>

        <CashierModel />

        {matchDownMobileJump && <JumpSection />}
      </Box>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
