import { useState, useRef, useEffect } from 'react';
import './index.scss';
import BlackDialog from 'self-component/DialogSelf';
import { tipsTransaction } from 'utils/api';
import { Input, Form, Button } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { toThousands } from 'utils/commonUtils';
import { Deposit_vault_off, Deposit_vault_on, Withdraw_vault_off, Withdraw_vault_on, Withdraw_vault_green, USDT_icon } from 'static/images';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { SET_USDT_ACCOUNT, SET_2FA_VERIFY } from 'store/actions';
import { getUserAccount } from 'store/actions/userAction';

const TIP = (props) => {
  const { open, onClose } = props;

  const [tipForm] = Form.useForm();

  const usdtAccount = useSelector((state) => state.customization.usdtAccount);
  const userInfor = useSelector((state) => state.customization.userInfor);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCancel = () => {
    onClose && onClose();
    tipForm.resetFields();
  };
  const handleSubit = async () => {
    setLoading(true);
    try {
      const formValue = await tipForm.validateFields();
      const data = {
        ...formValue,
        amount: Number(formValue.amount),
        userId: Number(formValue.userId)
      };
      const res = await tipsTransaction(data);

      dispatch(getUserAccount());
      enqueueSnackbar('success', { autoHideDuration: 2000, variant: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.msg) enqueueSnackbar(error.msg, { autoHideDuration: 2000, variant: 'error' });
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', flexGrow: 1 }}>
      <div>
        {
          <span
            style={{
              fontFamily: `"Geogrotesque Wide", sans-serif`,
              fontSize: '16px',
              color: '#576788',
              marginBottom: '5px'
            }}
          >
            {label}
          </span>
        }
        {required ? <span style={{ color: 'RGBA(239, 182, 30, 1)' }}> *</span> : ''}
      </div>

      {label == 'Amount' ? (
        <span
          style={{ color: 'RGBA(239, 182, 30, 1)', fontFamily: `"Geogrotesque Bl"`, marginLeft: 'auto', flexGrow: 1, textAlign: 'right' }}
        >
          ${toThousands(usdtAccount)}
        </span>
      ) : (
        ''
      )}
    </div>
  );

  const handlekeyDown = (e) => {
    if (e.key === 'Enter') return;
  };

  const handleMax = () => {
    tipForm.setFieldsValue({ amount: usdtAccount });
  };

  const handleTwoFa = () => {
    dispatch({ type: SET_2FA_VERIFY, verify_2fa: true });
  };

  return (
    <>
      <BlackDialog open={open} onClose={handleCancel} width={450} title={<div style={{ display: 'flex' }}>TIP</div>}>
        <div className="vault-content">
          <div style={{ marginTop: '20px' }}>
            <Form
              form={tipForm}
              layout="vertical"
              requiredMark={customizeRequiredMark}
              style={{ marginTop: '16px' }}
              initialValues={{ amount: '0.00' }}
            >
              <Form.Item
                label="Tip to"
                name="userId"
                required
                rules={[{ required: true, message: 'UID is require' }]}
                style={{ marginBottom: '10px' }}
              >
                <Input size="large" placeholder="Please enter UID" autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Amount"
                required
                rules={[{ required: true, message: 'Amount is require' }]}
                name="amount"
                style={{ marginBottom: '10px' }}
              >
                <Input
                  size="large"
                  placeholder="Please enter withdraw amount"
                  autoComplete="off"
                  suffix={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={USDT_icon} alt="" srcset="" width={20} />
                      <LoadingButton
                        disableRipple
                        onClick={() => handleMax()}
                        sx={{
                          height: '30px',
                          width: '60px',
                          margin: '0 auto',
                          display: 'flex',
                          justifyContent: 'center',
                          background: '#FFE619',
                          fontSize: '16px',
                          color: '#302B05',
                          borderRadius: '3px',
                          fontFamily: 'Geogrotesque Bl',
                          lineHeight: '16px',
                          marginLeft: '30px',
                          '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                        }}
                      >
                        Max
                      </LoadingButton>
                    </div>
                  }
                />
              </Form.Item>
              <Form.Item
                label="Password"
                required
                name="password"
                rules={[{ required: true, message: 'Password is require' }]}
                onKeyDown={handlekeyDown}
                style={{ marginBottom: '10px' }}
              >
                <Input.Password
                  size="large"
                  placeholder="********"
                  autoComplete="off"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
                  }
                />
              </Form.Item>
              {!!userInfor.twoFa && (
                <Form.Item
                  label="Two Factor"
                  name="code"
                  rules={[{ required: true, message: 'Two factor code is require' }]}
                  onKeyDown={handlekeyDown}
                >
                  <Input size="large" placeholder="Please enter two factor code" autoComplete="off" />
                </Form.Item>
              )}
            </Form>
            <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
              <LoadingButton
                onClick={handleCancel}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#5583AE',
                  borderRadius: '5px',
                  background: '#152D43',
                  flex: 1,
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`
                }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={() => handleSubit()}
                loading={loading}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#3E3A19',
                  borderRadius: '5px',
                  background: '#FFE619',
                  flex: 1,
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#FFEF6D',
                    boxShadow: '0px 0px 6px 0px #FFE936'
                  }
                }}
              >
                SEND TIP
              </LoadingButton>
            </div>

            {!!!userInfor.twoFa && (
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px' }}>
                <div style={{ color: '#5F7096', marginBottom: '10px' }}>lmprove your account security with Two-Factor Authentication</div>
                <LoadingButton
                  onClick={handleTwoFa}
                  loading={loading}
                  disableRipple
                  sx={{
                    fontSize: '14px',
                    color: '#5583AE',
                    borderRadius: '5px',
                    background: '#152D43',
                    // width: '84px',
                    minWidth: '100%',
                    height: '30px',
                    lineHeight: '30px',
                    fontFamily: `"Geogrotesque Bl", sans-serif`
                    // boxShadow:
                    //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                  }}
                >
                  Enable 2FA
                </LoadingButton>
              </div>
            )}
          </div>
        </div>
      </BlackDialog>
    </>
  );
};

export default TIP;
