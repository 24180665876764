import { Button, Dialog, DialogContent, Box, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './scss/deposit.scss';
import '../WalletFormItems/scss/withdraw.scss';

import { BNB, USDT, ETH, BTC, SOL, USDC, Go_back, DOLLAR, VECTOR, LTC } from 'static/images';

import { isAddress, formatAddress } from 'utils/utilTools';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAccount } from 'store/actions/userAction';
import CloseIcon from '@mui/icons-material/Close';
import CodeBoxCain from './../WalletFormItems/codeBox';
import {
  withdraw,
  getThreshold,
  getSupportedNetworks,
  getETHgas,
  getBTCgas,
  getLTCgas,
  getCurrencyList,
  sendVerifyCode,
  getErcSymbolList
} from 'utils/api';
import { PROFILE_ID, SET_IP_VERIFIY } from 'store/actions';

import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Input, Form, Row, Col, InputNumber } from 'antd';
import { toThousands } from 'utils/commonUtils';

import Transitions from 'ui-component/extended/Transitions';
import classNames from 'classnames';
import { isObjectEmpty } from 'utils/commonUtils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

const customizeRequiredMark = (label, { required }) => (
  <>
    {<span style={{ fontFamily: `"Geogrotesque Wide", sans-serif` }}>{label}</span>}
    {<span style={{ color: 'RGBA(239, 182, 30, 1)', marginLeft: '5px', fontSize: '20px' }}> *</span>}
  </>
);

export default function Withdraw(props) {
  const { closeModel } = props;

  const [coinType, setCoinType] = useState({});
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [coinPrice, setCoinPrice] = useState('1'); //价格比例
  const [openMsg, setOpenMsg] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [msgCode, setMsgCode] = useState('');
  const [networkId, setNetworkId] = useState(1);
  const [currencyId, setCurrencyId] = useState(1);
  const [countDown, setCountDown] = useState(300);
  const [isCountDown, setIsCountDown] = useState(false);
  const [showErcModel, setShowErcModel] = useState(false);
  const [iptCryptoName, setiptCryptoName] = useState('');
  const [cryptoList, setCryptoList] = useState([]);

  const [gasPrice, setGasPrice] = useState('-');

  const [form] = Form.useForm();

  const [dollerValue, setDollerValue] = useState('');
  const [amountValue, setAmountValue] = useState('');

  // const dollerValue = Form.useWatch((values) => values.doller?.replace(/\$\s?|(,*)/g, ''), form);
  // const amountValue = Form.useWatch(((values) => values.amount?.replace(/\$\s?|(,*)/g, ''), form));

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const userInfor = useSelector((state) => state.customization.userInfor);
  const twoFa = userInfor?.twoFa || '';
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  const depositAddress = userInfo?.rechargeAddress || '';
  const email = userInfo?.email || '';

  const btcPrice = window.sessionStorage.getItem('SET_BTC_PRICE') || 0;
  const bnbPrice = window.sessionStorage.getItem('SET_BNB_PRICE') || 0;
  const ethPrice = window.sessionStorage.getItem('SET_ETH_PRICE') || 0;
  const solPrice = window.sessionStorage.getItem('SET_SOL_PRICE') || 0;
  const ltcPrice = window.sessionStorage.getItem('SET_LTC_PRICE') || 0;
  const [balance, setBalance] = useState('0.00');
  const dispatch = useDispatch();
  const usdtAccount = useSelector((state) => state.customization.usdtAccount);

  const coinOptions = [
    { icon: BTC, symbol: 'BTC', name: 'Bitcoin', title: 'BTC', color: '#fd901b', disabled: false },
    { icon: ETH, symbol: 'ETH', name: 'Ethereum', title: 'ETHEREUM', color: '#7987CD', disabled: false, chain: 'erc' },
    { icon: LTC, symbol: 'LTC', name: 'Litecoin', title:"LTC",color: '#345D9D', disabled: false ,chain: 'bsc'},
    { icon: SOL, symbol: 'SOL', name: 'Solana', title: 'SOLANA', color: '#a258e7', disabled: false },
    { icon: BNB, symbol: 'BNB', name: 'Bsc', title: 'BNB', color: '#E9B40C', disabled: false, chain: 'bsc' },
    // { icon: USDT, symbol: 'USDT', name: 'BEP-20', title: 'USDT (BEP-20)', color: '#1BA27A', disabled: false, chain: 'bsc' },
    {
      name: 'ERC-20',
      otherChian: true,
      hasSelectModel: true,
      chain: 'erc',
      disabled: false
    }
  ];

  // const cryptoList = [{ crypto: 'USDT', price: '1', updown: '0.00%' }];

  const handleClickCoin = (coin) => {
    console.log(isObjectEmpty(userInfor), 'userInfor');
    if (!isObjectEmpty(userInfor) && userInfor.remoteLogin == 1) {
      dispatch({ type: SET_IP_VERIFIY, ipVerifiy: true });
      return;
    }
    if (coin?.disabled) return;
    if (coin?.hasSelectModel) {
      setShowErcModel(true);
    } else {
      setIsWithdraw(true);
      setCoinType(coin);
    }
  };

  const handleGoBack = () => {
    setShowErcModel(false);
    setIsWithdraw(false);
    form.resetFields();
  };

  const handleGoPath = () => {
    closeModel && closeModel();
    navigate('/game_profile');
    window.localStorage.setItem('transaction_type', 2);
    window.localStorage.setItem('PROFILE_ID', '4');
    dispatch({ type: PROFILE_ID, profileId: '4' });
  };

  //
  const onValuesChange = (item) => {
    const arr = Object.keys(item);
    if (arr.includes('doller')) {
      let value = item.doller;
      value = value && value.replace(/\$\s?|(,*)/g, '');
      const amountVal = (Number(value) / Number(coinPrice)).toFixed(4) || 0;

      form.setFieldsValue({ amount: amountVal });
      setDollerValue(value);
      setAmountValue(amountVal);
    }

    if (arr.includes('amount')) {
      let value = item.amount;
      value = value && value.replace(/\$\s?|(,*)/g, '');
      const dollerVal = (Number(value) * Number(coinPrice)).toFixed(2) || 0;
      console.log('dollerVal', dollerVal);
      form.setFieldsValue({ doller: dollerVal });
      setAmountValue(value);
      setDollerValue(dollerVal);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await form.validateFields();
      setLoading(true);
      setFormValues(res);
      const params = {
        address: depositAddress,
        toAddress: res.address,
        amount: Number(dollerValue),
        coinId: currencyId,
        chainId: networkId,
        twoFaCode: res?.twoFaCode || ''
      };

      const { data: threshold } = await getThreshold();
      if (Number(dollerValue) >= Number(threshold)) {
        setLoading(false);
        //second check
        if (!email) {
          return enqueueSnackbar('Please bind your email', { autoHideDuration: 2000 });
        } else {
          setOpenMsg(true);
          handleSendCode({ amount: amountValue, address: res.address });
        }
      } else {
        withdraw(params)
          .then((res) => {
            setOpenSuccess(true);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(err.msg, { autoHideDuration: 2000 });
          });
      }
    } catch (error) {
      console.log(error, 'is Error');
    }
  };

  const handleSubmitMsg = () => {
    if (!msgCode) return enqueueSnackbar('Please input code', { autoHideDuration: 2000 });
    if (msgCode?.trim().length !== 6) return enqueueSnackbar(' Invalid code', { autoHideDuration: 2000 });
    setLoading(true);
    const value = formValues.doller && formValues.doller.replace(/\$\s?|(,*)/g, '');
    const params = {
      address: depositAddress,
      toAddress: formValues.address,
      amount: Number(value),
      coinId: currencyId,
      chainId: networkId,
      authCode: msgCode,
      twoFaCode: formValues?.twoFaCode || ''
    };
    withdraw(params)
      .then((res) => {
        setLoading(false);
        setOpenMsg(false);
        setOpenSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err.msg, { autoHideDuration: 2000 });
      });
  };

  // 发送验证码
  const handleSendCode = ({ amount, address }) => {
    if (isCountDown) return;
    // if(!email) enqueueSnackbar('Please bind your email', { autoHideDuration: 2000 });
    sendVerifyCode({ amount: amount || amountValue, coinName: coinType.symbol, toAddress: address || formValues.address });
    setIsCountDown(true);
    // if (!isCountDown) {
    let num = 300;
    let time = setInterval(() => {
      if (num > 0) {
        num--;
        setCountDown(num);
      } else {
        num = 300;
        setCountDown(num);
        clearInterval(time);
        setIsCountDown(false);
      }
    }, 1000);
    // }
  };
  const handleCloseMsgCode = () => {
    setOpenMsg(false);
  };

  const getMsgCode = (code) => {
    setMsgCode(code);
  };

  const updateInfo = async () => {
    await dispatch(getUserAccount());
    setBalance(usdtAccount);
  };

  const handleClickOk = () => {
    setOpenSuccess(false);
    closeModel && closeModel();
    // navigate('/');
    updateInfo();
  };

  useEffect(() => {
    updateInfo();
  }, []);

  useEffect(() => {
    if (coinType.symbol === 'BTC') setCoinPrice(btcPrice);
    if (coinType.symbol === 'BNB') setCoinPrice(bnbPrice);
    if (coinType.symbol === 'SOL') setCoinPrice(solPrice);
    if (coinType.symbol === 'LTC') setCoinPrice(ltcPrice);
    if (coinType.symbol === 'USDT') setCoinPrice(1);
    if (coinType.symbol === 'ETH') setCoinPrice(ethPrice);

    if (coinType.symbol === 'ETH' || coinType.chain === 'erc') {
      setGasPrice('-');
      getETHgas().then((res) => {
        if (res.code === 200) setGasPrice(res.data);
      });
    } else if (coinType.symbol === 'BTC') {
      setGasPrice('-');
      getBTCgas().then((res) => {
        if (res.code === 200) setGasPrice(res.data);
      });
    } else if (coinType.symbol === 'LTC') {
      setGasPrice('-');
      getLTCgas().then((res) => {
        if (res.code === 200) setGasPrice(res.data);
      });
    } else setGasPrice(0.29);

    if (!coinType.symbol) return;
    // get networkId
    getSupportedNetworks().then((res) => {
      if (res.code === 200) {
        const [data] = res.data.filter((item) => {
          return coinType.chain === 'bsc' ? item.name === 'BSC' : item.name === 'Mainnet';
        });
        setNetworkId(data.chainId);
      }
    });
    // get coinId
    getCurrencyList().then((res) => {
      if (res.code === 200) {
        const [data] = res.data.filter((item) => {
          return item.symbol === coinType.symbol;
        });
        setCurrencyId(data.id);
      }
    });
  }, [coinType]);

  useEffect(() => {
    if (showErcModel) {
      getErcSymbolList().then((res) => {
        if (res.code === 200) {
          const arr = res.data.map((item) => {
            return { icon: item.image, chain: 'erc', disabled: false, name: item.symbol, title: item.symbol, ...item };
          });
          const trumpIndex = arr.findIndex((item) => item.symbol === 'TRUMP');
          arr.splice(trumpIndex, 1);
          setCryptoList(arr);
        }
      });
    }
  }, [showErcModel]);
  // useEffect(() => {
  //   if (coinPrice) form.setFieldsValue('amount', Number() / Number(coinPrice));
  // }, [coinPrice]);

  return (
    <Box className="deposit-box">
      {!isWithdraw && !showErcModel && (
        <Box>
          <Box className="title">WITHDRAWAL OPTIONS</Box>
          <Box className="deposit-card-box">
            {coinOptions.map((item) =>
              item.otherChian ? (
                <Box key={item.name} className="deposit-item" onClick={() => handleClickCoin(item)}>
                  <div style={{ display: 'flex', gap: '6px' }}>
                    <img src={USDT} alt="" className="deposit-icon" />
                    <img src={USDC} alt="" className="deposit-icon" />
                  </div>
                  <Box sx={{ color: item?.color }} className="deposit-name">
                    {item.name}
                  </Box>
                  <Box className="deposit-symbol">{'[USDx, and more]'}</Box>
                </Box>
              ) : (
                <Box key={item.title} className="deposit-item" onClick={() => handleClickCoin(item)}>
                  <img src={item.icon} alt={item.name} className="deposit-icon" />
                  <Box sx={{ color: item.color }} className="deposit-name">
                    {item.name}
                  </Box>
                  <Box className="deposit-symbol">{item.symbol}</Box>
                  {item.disabled && <Box className="disabled"></Box>}
                </Box>
              )
            )}

            {/* <Box className="deposit-item" style={{ justifyContent: 'center' }}>
              <img src={MORE} alt="more" />
            </Box> */}
          </Box>
        </Box>
      )}

      {!isWithdraw && showErcModel && (
        <Box className="deposit-content">
          <Box className="content-title">
            <img src={Go_back} alt="goBack" className="go-back" onClick={handleGoBack} />
            <Box className="title-name">WITHDRAW ERC-20 TOKENS</Box>
          </Box>

          <Box>
            {/* <Input
              size="large"
              placeholder="Search crypto"
              autoComplete="off"
              value={iptCryptoName}
              onChange={(e) => setiptCryptoName(e.target.value)}
            /> */}

            <Box sx={{ mt: '24px', mb: '30px' }}>
              <div className={classNames('crypto-list', 'crypto-header')}>
                <div className={classNames('crypto-list-crypto')}>CRYPTO</div>
                <div className="crypto-list-price">PRICE</div>
                {/* <div className="crypto-list-updown">PRICE 24H %</div> */}
              </div>
              {cryptoList.map((item, index) => (
                <div key={index} className={classNames('crypto-list')} onClick={() => handleClickCoin(item)}>
                  <div className={classNames('crypto-list-crypto')}>
                    <img src={item.icon} alt={item.name} style={{ width: '20px', marginRight: '6px' }} />
                    {item.symbol}
                  </div>
                  <div className="crypto-list-price">$ {Number(item.price).toFixed(4)}</div>
                  {/* <div className="crypto-list-updown">{item.increaseInPrice} %</div> */}
                </div>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {isWithdraw && (
        <Box className="deposit-content">
          <Box className="content-title">
            <img src={Go_back} alt="goBack" className="go-back" onClick={handleGoBack} />
            <img src={coinType.icon} alt="coin" className="title-icon" />

            <Box className="title-name">WITHDRAW {coinType.title?.toUpperCase()}</Box>

            <Box className="title-desc" onClick={handleGoPath}>
              View Transactions
            </Box>
          </Box>
          <Box className="content-qr">
            <Box className="qr-box">
              <Box>
                <Box className="qr-desc">
                  Please enter the {coinType.symbol} wallet address you wish to receive the funds on. Once confirmed, the withdrawal is
                  usually processed within a few minutes.
                </Box>
                {coinType.symbol === 'BTC' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" />
                    BTC withdrawals currently only support the following address types: Nested Segwit (P2SH-P2WPKH) & Taproot (P2TR).
                  </Box>
                )}
                {coinType.symbol === 'BTC' && (
                  <Box className="tips">
                    <img src={VECTOR} alt="vector" />
                    Please do not enter any other types of BTC addresses.
                  </Box>
                )}
              </Box>
            </Box>

            <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onValuesChange={onValuesChange}>
              <Form.Item
                label={
                  <div className="diy-label">
                    <Box sx={{ mb: '8px' }}>RECEIVING {coinType.symbol?.toUpperCase()} ADDRESS</Box>
                  </div>
                }
                rules={[
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject('please input address');
                      }
                      if (!isAddress(value, coinType.symbol)) {
                        return Promise.reject('Invalid address');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                name="address"
              >
                <Input size="large" placeholder={`Paste your ${coinType.symbol}  wallet address here`} autoComplete="off" />
              </Form.Item>
              {twoFa == 1 && (
                <Form.Item
                  label={
                    <div className="diy-label">
                      <Box sx={{ mb: '8px' }}>2FA Code</Box>
                    </div>
                  }
                  name="twoFaCode"
                >
                  <Input size="large" placeholder={`Paste your 2fa code here`} autoComplete="off" />
                </Form.Item>
              )}
              <Form.Item
                label={<div className="diy-label">WITHDRAWAL AMOUNT</div>}
                // name="amount"
              >
                <Box className="from-row">
                  <Box className="row-item-ipt">
                    <Form.Item
                      name="doller"
                      style={{ marginBottom: 0 }}
                      normalize={(value) => value && toThousands(value)}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required'
                        },
                        {
                          validator(_, value) {
                            if (value && Number(value) < 10) {
                              return Promise.reject(new Error(`Insufficient amount to cover withdrawal fee`));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        size="large"
                        // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                        // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        placeholder="Minimum 10 USDT"
                        prefix={
                          <div className="flex-box">
                            <img src={DOLLAR} style={{ width: '20px', height: '20px' }} alt="icon" />
                            <Box sx={{ ml: '8px' }}>$</Box>
                          </div>
                        }
                      />
                    </Form.Item>
                  </Box>
                  <Box className="equals-sign">=</Box>
                  <Box className="row-item-ipt">
                    <Form.Item
                      name="amount"
                      normalize={(value) => value && toThousands(value)}
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          validator(_, value) {
                            // if (!value) return Promise.reject(new Error('please input amounts'));
                            if (isNaN(value) || Number(value) < 0) {
                              return Promise.reject(new Error('error ipt'));
                            }
                            if (Number(value) > Number(balance)) {
                              return Promise.reject(new Error(`Only $ ${toThousands(balance)} can be withdraw`));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        size="large"
                        // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // formatter={(value) => toThousands(value)}
                        // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder="Minimum 10 USDT"
                        prefix={
                          <div className="flex-box">
                            <img src={coinType.icon} style={{ width: '20px', height: '20px' }} alt="icon" />
                          </div>
                        }
                      />
                    </Form.Item>
                  </Box>
                  {/* <Box> */}
                  <LoadingButton
                    loading={loading}
                    disableRipple
                    onClick={handleSubmit}
                    className="submit-btn"
                    sx={{ '&:hover': { backgroundColor: '#ffe619' } }}
                  >
                    REQUEST WITHDRAWAL
                  </LoadingButton>
                  {/* </Box> */}
                </Box>
              </Form.Item>
            </Form>

            <Box className="withdraw-tips">
              * Network fee {gasPrice} USDT (This Network Fee is for reference only. Please refer to the actual Network Fee consumed during
              the transaction.)
            </Box>
            <Box className="withdraw-tips">
              * You will receive the specified {coinType.symbol === 'USDT' ? 'USDT' : coinType.title} amount to your withdraw address
            </Box>
            <Box className="withdraw-tips">
              * The value subtracted from your balance may vary between now and the time we process your withdrawal{' '}
            </Box>
            {/* {coinType.symbol === 'BTC' && (
              <Box className="withdraw-tips">
                * BTC withdrawals currently only support the following address types: Nested Segwit (P2SH-P2WPKH) & Taproot (P2TR).
              </Box>
            )}
            {coinType.symbol === 'BTC' && (
              <Box className="withdraw-tips">
                * Please do not enter any other types of BTC addresses.
              </Box>
            )} */}
          </Box>
        </Box>
      )}

      {/* second verify */}
      <Dialog
        TransitionComponent={Transition}
        onClose={handleCloseMsgCode}
        aria-labelledby="customized-dialog-title"
        open={openMsg}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            // height: '250px',
            // maxHeight: '250px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)',
            '@media (max-width: 800px)': {
              width: '95vw'
            }
          },
          '& :focus-visible': {
            outline: 'none'
          }
        }}
      >
        <div className="withdraw-dia-top">
          <div className="dia-top-arrow">
            <IconButton
              aria-label="close"
              onClick={handleCloseMsgCode}
              sx={{
                color: '#576788',
                transition: 'all 0.2s ease-in',
                '&:hover': {
                  color: '#5096FF',
                  background: 'none'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dia-top-text">TWO-STEP VERIFICATION VIA EMAIL</div>
        </div>
        <DialogContent sx={{ background: '#151E2F', padding: '20px 16px' }}>
          <Box sx={{ color: '#8299C8', textAlign: 'center', marginBottom: '20px' }}>
            Please enter the code sent to player
            <span className="auth-mail" style={{ color: '#fff' }}>
              {' '}
              {email}
            </span>
          </Box>

          <Box sx={{ padding: '0 20px' }}>
            <CodeBoxCain onChange={getMsgCode} />
          </Box>
          {isCountDown ? (
            <Box className="code-msg">Resend code ({countDown}s)</Box>
          ) : (
            <Box className="code-msg" onClick={handleSendCode}>
              Send code
            </Box>
          )}
          <Box className="tip-box">
            <Box className="tip-row">
              <Box className="tip-row-title">You are withdrawing</Box>
              <Box className="tip-bck">
                <img src={coinType.icon} alt="coin" className="tip-icon" /> {amountValue} {coinType.symbol}
              </Box>
            </Box>
            <Box sx={{ mt: '8px' }} className="tip-row">
              <Box className="tip-row-title">to the address</Box>
              <Box className="tip-bck">{formatAddress(formValues.address)}</Box>
            </Box>
          </Box>
          <Box className="tips">Your withdrawal will be processed on {coinType.title?.toUpperCase()}</Box>
          <Button
            disableRipple
            sx={{
              height: '40px',
              width: '180px',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              background: '#FFE619',
              fontSize: '20px',
              color: '#302B05',
              borderRadius: '5px',
              fontFamily: 'Geogrotesque Bl',
              '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
            }}
            onClick={handleSubmitMsg}
          >
            VERIFY
          </Button>
        </DialogContent>
      </Dialog>

      {/* success dialog */}
      <Dialog
        onClose={handleClickOk}
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={openSuccess}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            // height: '250px',
            // maxHeight: '250px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div className="withdraw-dia-top">
          <div className="dia-top-arrow">
            <IconButton
              aria-label="close"
              onClick={handleClickOk}
              sx={{
                color: '#576788',
                transition: 'all 0.2s ease-in',
                '&:hover': {
                  color: '#5096FF',
                  background: 'none'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {/* <div className="dia-top-text">CONGRATULATIONS!</div> */}
          <div className="dia-top-text">PENDING</div>
        </div>
        <DialogContent sx={{ background: '#151E2F', padding: '20px 16px' }}>
          {/* <img src={BNB} alt="img" style={{ width: '100px' }} /> */}
          {/* <Box className="auth-title">Congratulations!</Box> */}
          <Box className="notic">
            {/* <Box sx={{ mb: '16px' }}>You withdraw</Box> */}
            <Box className="notic-info">
              <Box className="sec-tip-bck">
                <img src={coinType.icon} alt="" /> {formValues.amount} {coinType.symbol}
              </Box>
              to
              <Box className="sec-tip-bck">{formatAddress(formValues.address)}</Box>
            </Box>
          </Box>

          <Button
            // className="comfirm"
            disableRipple
            sx={{
              height: '40px',
              width: '180px',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              background: '#FFE619',
              fontSize: '20px',
              color: '#302B05',
              borderRadius: '5px',
              marginTop: '30px',
              fontFamily: 'Geogrotesque Bl',
              '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
            }}
            onClick={handleClickOk}
          >
            OKAY!
          </Button>
          <Box className="history" onClick={handleGoPath}>
            View
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
