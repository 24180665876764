
import {
  casino_yellow,
  bonus_yellow,
  favorites,
  favorites_blue,
  Bits,
  Bits_blue,
  slot,
  slot_blue,
  liveCasino,
  liveCasino_blue,
  gameShow,
  gameShow_blue,
  blackjack,
  blackjack_blue,
  roulette,
  roulette_blue,
  Baccarat,
  Baccarat_blue,
  challenge,
  challenge_blue,
  profile_yellow,
  Wallet,
  Wallet_blue,
  VIP,
  VIP_blue,
  referrals,
  referrals_blue,
  transactions,
  transactions_blue,
  settings,
  settings_blue,
  esports,
  esports_blue,
  Sport2_blue,
  Sport2,
  sport_yellow,
  trade_yellow,
  updown,
  updown_blue,
  crypto_yellow,
  promotions_yellow,
  raceOn
} from 'static/images';

import TradeList from 'ui-component/tradeCard/tradeList';

const Menu = [
  {
    title: 'BONUS',
    icon: bonus_yellow,
    iconOn: bonus_yellow,
    MenuItem: [],
    url: '/game_claim'
  },
  {
    title: 'CASINO',
    icon: casino_yellow,
    iconOn: casino_yellow,
    MenuItem: [
      { icon: favorites, iconOn: favorites_blue, label: 'FAVOURITES', url: '/favorite' },
      { icon: Bits, iconOn: Bits_blue, label: '21BITS ORIGINALS', url: '/21bits-originals' },
      // { icon: poker, iconOn: poker_blue, label: 'HUSTLER POKER', url: '/hustler' },
      // { icon: crash, iconOn: crash_blue, label: 'CRASH', url: '/crash' },
      // { icon: plinko, iconOn: plinko_blue, label: 'PLINKO', url: '/plinko' },
      // { icon: roulette, iconOn: roulette_blue, label: 'ROULETTE', url: '/roulette' }

      { icon: slot, iconOn: slot_blue, label: 'SLOTS', url: '/slots' },
      // { icon: liveCasino, iconOn: liveCasino_blue, label: 'LIVE CASINO', url: 'nothing' },
      // { icon: gameShow, iconOn: gameShow_blue, label: 'VIRTUAL SPORT', url: 'nothing' },
      // { icon: blackjack, iconOn: blackjack_blue, label: 'BLACKJACK', url: 'nothing' },
      // { icon: roulette, iconOn: roulette_blue, label: 'ROULETTE', url: 'nothing' },
      // { icon: Baccarat, iconOn: Baccarat_blue, label: 'BACCARAT', url: 'nothing' }
      { icon: liveCasino, iconOn: liveCasino_blue, label: 'LIVE CASINO', url: '/live_casino', hiddenStream: true },
      { icon: gameShow, iconOn: gameShow_blue, label: 'VIRTUAL SPORT', url: '/virtual-sport', hiddenStream: true },
      { icon: blackjack, iconOn: blackjack_blue, label: 'BLACKJACK', url: '/blackjack', hiddenStream: true },
      { icon: roulette, iconOn: roulette_blue, label: 'ROULETTE', url: '/roulette-pp', hiddenStream: true },
      { icon: Baccarat, iconOn: Baccarat_blue, label: 'BACCARAT', url: '/baccarat', hiddenStream: true },
      { icon: challenge, iconOn: challenge_blue, label: 'CHALLENGE', url: '/challenge', hiddenStream: true }
    ]
  },
  // {
  //   title: 'DECENTRALIZED',
  //   icon: decentralized,
  //   iconOn: decentralized_blue,
  //   MenuItem: [
  //     // { icon: BNB, iconOn: BNB_blue, label: '$BNB LOTTERY', url: '/raffle' },
  //     // { icon: prediction, iconOn: prediction_blue, label: 'PREDICTION', url: '/prediction' }
  //     { icon: prediction, iconOn: prediction_blue, label: 'PREDICTION', url: '/game_prediction' }
  //   ]
  // },
  {
    title: 'SPORTS',
    icon: sport_yellow,
    iconOn: sport_yellow,
    MenuItem: [
      { icon: Sport2, iconOn: Sport2_blue, label: 'SPORT', url: '/game_sports', hiddenStream: true },
      { icon: esports, iconOn: esports_blue, label: 'ESPORT', url: '/game_esports', hiddenStream: true }
    ]
  },
  {
    title: '$5K RACE',
    icon: raceOn,
    iconOn: raceOn,
    MenuItem: [],
    url: '/game_race'
  },
  {
    title: 'TRADE',
    icon: trade_yellow,
    iconOn: trade_yellow,
    MenuItem: [{ icon: updown, iconOn: updown_blue, label: 'UP DOWN', url: '/game_upDown', hiddenStream: true }]
  },
  {
    title: 'PROMOTIONS',
    icon: promotions_yellow,
    iconOn: promotions_yellow,
    MenuItem: [],
    url: '/game_promotions'
  },
  {
    title: 'PROFILE',
    icon: profile_yellow,
    iconOn: profile_yellow,
    needToken: true,
    MenuItem: [
      { icon: Wallet, iconOn: Wallet_blue, label: 'CASHIER', url: '/game_profile/1' },
      { icon: VIP, iconOn: VIP_blue, label: 'VIP', url: '/game_profile/2' },
      { icon: referrals, iconOn: referrals_blue, label: 'REFERRALS', url: '/game_profile/3' },
      { icon: transactions, iconOn: transactions_blue, label: 'TRANSACTIONS', url: '/game_profile/4' },
      { icon: settings, iconOn: settings_blue, label: 'SETTINGS', url: '/game_profile/5' }
    ]
  },
  {
    title: 'Crypto Futures',
    icon: crypto_yellow,
    iconOn: crypto_yellow,
    eleCopt: () => <TradeList type="list" />
  }
];

export default Menu;
