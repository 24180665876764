import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const Buttom = (props) => {
  const { onClick, loading, disable, sx } = props;
  return (
    <LoadingButton
      onClick={onClick && onClick}
      loading={loading}
      disableRipple
      sx={{
        cursor: disable ? 'not-allowed' : 'pointer',
        pointerEvents: disable ? 'none' : 'auto',
        height: '40px',
        minWidth: '225px',
        background: disable ? '#014800' : '#15771E',
        fontSize: '16px',
        color: '#fff',
        border: '1px solid #50FE03',
        borderRadius: '5px',
        fontWeight: '600',
        fontFamily: `'Geogrotesque Bl', sans-serif`,
        textTransform: 'uppercase',
        // boxShadow:
        //   ' 0px 0px 0px 0px #025C00, 0px 0px 1px 0px #025C00, 0px 0px 2px 0px #025C00, 0px 0px 4px 0px #025C00, 0px 0px 6px 0px #025C00, 0px 0px 11px 0px #025C00',
        ...sx
      }}
    >
      {props.children}
    </LoadingButton>
  );
};

export default Buttom;
