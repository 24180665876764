import React, { useEffect, useState, useRef } from 'react';
import { weeklyRaceRank } from 'utils/api';
const RaceTimeCount = ({text}) => {
  const [timeCount, setTimeCount] = useState({ d: 0, h: 0, m: 0, s: 0 });
  const timeLeftRef = useRef(0);
  const timeHanleRef = useRef(null);
  useEffect(() => {
    if (timeLeftRef.current === 0 && timeHanleRef.current) {
      // onComplete();
      clearInterval(timeHanleRef.current);
      getWeeklyRaceRank();
    }
  }, [timeLeftRef.current]);
  useEffect(() => {
    getWeeklyRaceRank();
  }, []);
  const getWeeklyRaceRank = async () => {
    try {
      const res = await weeklyRaceRank();
      const { left_time, ranks } = res.data;
      timeLeftRef.current = left_time;
      const intervalId = setInterval(() => {
        secondsToHms();
      }, 1000);
      timeHanleRef.current = intervalId;
    } catch (e) {
      console.log(e);
    }
  };
  function secondsToHms() {
    const d = parseInt(timeLeftRef.current / 60 / 60 / 24);
    const h = parseInt((timeLeftRef.current / 60 / 60) % 24);
    const m = parseInt((timeLeftRef.current / 60) % 60);
    const s = parseInt(timeLeftRef.current % 60);

    timeLeftRef.current = timeLeftRef.current - 1;
    setTimeCount({
      d: d < 10 ? '0' + d : `${d}`,
      h: h < 10 ? '0' + h : `${h}`,
      m: m < 10 ? '0' + m : `${m}`,
      s: s < 10 ? '0' + s : `${s}`
    });
  }
  return (
    <div style={{fontSize:'12px',lineHeight:'12px',marginLeft:'35px'}}>
        {timeCount.d}:{timeCount.h}:{timeCount.m}:{timeCount.s}
    </div>
  );
};

export default RaceTimeCount;
