import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Transitions from 'ui-component/extended/Transitions';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

const DialogSelf = (props) => {
  const { open, onClose, title, width, children, height } = props;
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const widthF = width || '375px';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="self-dialog-title"
      aria-describedby="self-dialog-description"
      width="100%"
      sx={{
        '& .MuiDialog-container': {
          minWidth: '375px'
        },
        '& .MuiPaper-root': {
          width: widthF,
          maxWidth: widthF,
          // minHeight: '154px',
          background: '#151E2F',
          padding: 0,
          margin: 0,
          borderRadius: '6px',
          // boxShadow: 'inset 0px 0px 4px #121416',
          // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)',
          transform: 'translate3d(0, 0, 0)',
          height: height || 'fit-contetnt',
          '@media (max-width:1030px)': {
            margin: 0,
            // width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '800px'
          },
          '@media (max-width:800px)': {
            margin: 0,
            width: '100%',
            height: '100%',
            maxHeight: '100%'
          },
          '@media (max-width:450px)': {
            margin: 0,
            width: `calc(95%)`,
            height: '100%',

            maxHeight: '100%'
          }
        }
      }}
    >
      <DialogContent className="dia-balck">
        <div className="self-dialog-balck-top">
          <div slot="title">{title}</div>
          <IconButton
            onClick={onClose}
            sx={{
              color: '#576788',
              width: '12px',
              position: 'absolute',
              right: '18px',
              top: '50%',
              transform: 'translateY(-50%)',
              '&:hover': {
                color: '#5096FF'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dia-balck-content">{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSelf;
