import { configureChains, createConfig } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient, webSocketPublicClient } = configureChains([bsc], [publicProvider()]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Okexchain',
        getProvider: () =>
          // @ts-ignore
          typeof window !== 'undefined' ? window.okexchain : undefined
      }
    })
  ],
  logger: {
    warn: null
  },
  publicClient,
  webSocketPublicClient
});

// jsonRpcProvider({
//   rpc: (chain) => ({
//     http: `http://127.0.0.1:7545`,
//   }),
// }),
